/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { AsideMenuItem } from './AsideMenuItem'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { shallowEqual, useSelector } from 'react-redux'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'
import { useDispatch } from 'react-redux'
import { getUserByToken } from '../../../../app/modules/auth/redux/AuthCRUD'
import { useLang } from '../../../../_metronic/i18n/Metronici18n'
import { getLists } from '../../../../app/pages/dashboard/redux/DashboardCrud';

export default function AsideMenuMain() {
  const intl = useIntl()
  const { data } = useSelector(({ auth }) => auth.user);
  const dispatch = useDispatch()
  const { swedishLang, englishLang } = useSelector(({ auth }) => auth);
  const selectedLang = useLang()
  const [localLanguage, setLocalLanguage] = useState({})
  const [totalnuture, settotalnuture] = useState([]);
  const [totalfedback, settotalfeedback] = useState();

  useEffect(() => {
    if (selectedLang === 'english') {
      setLocalLanguage(englishLang)
    } else {
      setLocalLanguage(swedishLang)
    }
  }, [selectedLang, swedishLang, englishLang]);


  const getNutureLists = () => {
    let filter = `?sort=-id&filter[owner][eq]=${data.id}&meta=total_count,result_count,filter_count&fields=id`;
    getLists('NUTURE', filter)

      .then(({ data: { meta } }) => {
        settotalnuture(meta.result_count)
      })
      .catch((err) => {
        throw err;
      });
  };

  const getFeedbackLists = () => {
    let filter = `?sort=-id&filter[customer][eq]=${data.id}&meta=total_count,result_count,filter_count&fields=id`;
    getLists('FEEDBACK', filter)

      .then(({ data: { meta } }) => {
        settotalfeedback(meta.result_count)
      })
      .catch((err) => {
        throw err;
      });
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      console.log("timoutttt")
      getNutureLists()
      getFeedbackLists()
    }, 1000);
    // return () => clearTimeout(timer);
  }, []);



  useEffect(async () => {
    const { data: user } = await getUserByToken()
    dispatch(auth.actions.fulfillUser(user))
  }, [])

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/abstract/abs029.svg'
        title={localLanguage.overview}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/list'
        icon='/media/icons/duotune/general/gen010.svg'
        title={localLanguage.list}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/answerslist'
        icon='/media/icons/duotune/communication/com012.svg'
        title={localLanguage.add_answer}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/salesrep'
        icon='/media/icons/duotune/Ticket.svg'
        title={localLanguage.add_sales_rep_title}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/nuturelist'
        icon='/media/icons/duotune/general/gen014.svg'
        title={`${localLanguage.nuture_list}`}
        fontIcon='bi-app-indicator'
        total={totalnuture}
      />

      <AsideMenuItem
        to='/crm'
        icon='/media/icons/duotune/general/gen049.svg'
        title={localLanguage.crm_connection}
        fontIcon='bi-app-indicator'
      />
      {/* <AsideMenuItemWithSub
        to='/reports'
        icon='/media/icons/duotune/crm.svg'
        title={localLanguage.campaign}
        fontIcon='bi-app-indicator'
        hasBullet={false}
      >
        <AsideMenuItem
          to='/e-post'
          icon='/media/icons/duotune/crm.svg'
          title={localLanguage.email}
          fontIcon='bi-app-indicator'
          hasBullet={true}
        />
        <AsideMenuItem
          to='/ads'
          icon='/media/icons/duotune/crm.svg'
          title={localLanguage.ads}
          fontIcon='bi-app-indicator'
          hasBullet={true}
        />
        <AsideMenuItem
          to='/mailings'
          icon='/media/icons/duotune/crm.svg'
          title={localLanguage.mailings}
          fontIcon='bi-app-indicator'
          hasBullet={true}
        />
        <AsideMenuItem
          to='/tele-processing'
          icon='/media/icons/duotune/crm.svg'
          title={localLanguage.tele_proces}
          fontIcon='bi-app-indicator'
          hasBullet={true}
        />
      </AsideMenuItemWithSub> */}
      <AsideMenuItem
        to='/feedback-list'
        icon='/media/icons/duotune/coding/cod001.svg'
        title={localLanguage.feedback}
        fontIcon='bi-app-indicator'
        total={totalfedback}
      />
      {/* <AsideMenuItem
        to='/review'
        icon='/media/icons/duotune/general/gen049.svg'
        title={localLanguage.review}
        fontIcon='bi-app-indicator'
      /> */}
      {/* <AsideMenuItem
        to='/meeting'
        icon='/media/icons/duotune/general/gen049.svg'
        title={localLanguage.meeting_guide}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/crm'
        icon='/media/icons/duotune/general/gen049.svg'
        title={localLanguage.crm_connection}
        fontIcon='bi-app-indicator'
      /> */}
      {/* <AsideMenuItem
        to='/pricing'
        icon='/media/icons/duotune/general/gen009.svg'
        title={'Öka konvertering'}
        fontIcon='bi-app-indicator'
      /> */}
      {/* <AsideMenuItem
        to='/reports'
        icon='/media/icons/duotune/general/gen035.svg'
        title={localLanguage.special_report}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/sales-training'
        icon='/media/icons/duotune/general/gen009.svg'
        title={localLanguage.sales_training}
        fontIcon='bi-app-indicator'
        imgs={"../media/logos/lock.png"}
        lock={data.islock}
      /> */}
      <div className="support-section">
        <img
          alt='Logo'
          className='logo support'
          src={"../media/logos/support.png"}
        />
      </div>
    </>
  )
}
