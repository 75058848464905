/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useFormik } from 'formik'
import * as auth from '../redux/AuthRedux'
import { login } from '../redux/AuthCRUD'
import { useLocation, useParams } from "react-router";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}


/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const [userType, setUserType] = useState('PARTNER')
  const dispatch = useDispatch()
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (location && location.pathname === '/auth/login') {
      setUserType('SUPERADMIN');
    }

  }, [location]);
  /*
    useEffect(() => {
      if (paramss) {
        console.log("paramss.token", paramss)
        let token = paramss.token
        // localStorage.setItem("token", token);
       
       dispatch(auth.actions.login(token))
      }
    }, [paramss]);
    */

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)

      login(values.email, values.password)
        .then(({ data: { data } }) => {
          setLoading(false)

          dispatch(auth.actions.login(data.token))
        })
        .catch(() => {
          setLoading(false)
          setSubmitting(false)
          setStatus('The login detail is incorrect')
        })
      // }, 1000)
    },
  })

  return (
    <>

      {/* <PageLoader pageLoaderOpen={loading} setPageLoaderOpen={setLoading} /> */}
      <div className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white">
        <div className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10">

          <video autoPlay muted loop id="background-video">
            <source src={'https://salescrmapi.inspirepro.co.in/uploads/salescrm/originals/e0a9f941-212f-441c-823f-04933ea573ef.mp4'} type="video/mp4"></source>
          </video>
          {/* <div className="d-flex flex-row-fluid flex-column justify-content-between">
            <a className="flex-column-auto mt-5 pb-lg-0 pb-10" href="#">
              <img alt="Logo" className="max-h-70px" src="../media/logos/logo_white.png" />
            </a> */}
          {/* <div className="flex-column-fluid d-flex flex-column justify-content-center">
              <h3 className="font-size-h1 mb-5 text-white">Säljare</h3>
            </div> */}
          {/* </div> */}
        </div>
        <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
          <div>
            {/* <Button
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              Dashboard
                </Button> */}
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={handleClose}>English</MenuItem>
              <MenuItem onClick={handleClose}>Swedish</MenuItem>
            </Menu>
          </div>
          <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
            <div className="login-form login-signin" id="kt_login_signin_form">

              {/* begin::Heading */}
              <div className='text-center mb-10'>
                <h1 className='text-dark mb-3'>Logga in på leadsportalen</h1>
              </div>
              {/* begin::Heading */}
              <div className='container'>
                <div className="main">
                  <form
                    className='form w-100'
                    onSubmit={formik.handleSubmit}
                    noValidate
                    id='kt_login_signin_form'
                  >

                    {formik.status ? (
                      <div className='mb-lg-15 alert alert-danger'>
                        <div className='alert-text font-weight-bold'>{formik.status}</div>
                      </div>
                    ) : null}

                    {/* begin::Form group */}
                    <div className='fv-row mb-10'>
                      {/* <label className='form-label fs-6 fw-bolder text-dark'>Email</label> */}
                      <input
                        placeholder='Email'
                        {...formik.getFieldProps('email')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          { 'is-invalid': formik.touched.email && formik.errors.email },
                          {
                            'is-valid': formik.touched.email && !formik.errors.email,
                          }
                        )}
                        type='email'
                        name='email'
                        autoComplete='off'
                      />
                      {formik.touched.email && formik.errors.email && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.email}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* end::Form group */}

                    {/* begin::Form group */}
                    <div className='fv-row mb-10'>
                      <div className='d-flex justify-content-between mt-n5'>
                        <div className='d-flex flex-stack mb-2'>
                        </div>
                      </div>
                      <input
                        type='password'
                        placeholder='password'
                        autoComplete='off'
                        {...formik.getFieldProps('password')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid': formik.touched.password && formik.errors.password,
                          },
                          {
                            'is-valid': formik.touched.password && !formik.errors.password,
                          }
                        )}
                      />
                      {formik.touched.password && formik.errors.password && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.password}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* end::Form group */}

                    {/* begin::Action */}
                    <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                      <a className="text-dark-50 text-hover-primary my-3 mr-2" id="kt_login_forgot" href="#"></a>
                      <button id="kt_login_signin_submit" type="submit" className="btn btn-primary font-weight-bold px-9 py-4 my-3"><span> {!loading && <span className='indicator-label'>Sign In</span>}
                        {loading && (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}</span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )

  //return (
  //    <PageLoader pageLoaderOpen={loading} setPageLoaderOpen={setLoading} /> 
  //)
}
