/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { PageTitle } from '../../../_metronic/layout/core';
import {
	StatisticsWidget1
} from '../../../_metronic/partials/widgets';
import Button from '@material-ui/core/Button';
import { updateUser, getLists, postMail } from '../dashboard/redux/DashboardCrud'
import { useSelector } from "react-redux";

export default function Boostconverstion() {
	const intl = useIntl();
	const [customerData, setCustomerData] = React.useState({})
	const [statusSent, setStatusSent] = React.useState('')
	const { data } = useSelector(({ auth }) => auth.user);

	useEffect(() => {
		if (data && data.id) {
			getCustomerData(data)
		}

	}, [data])

	const getCustomerData = (data, accessToken) => {
		let filter = `?filter[customer_id][eq]=${data.id}&fields=*.*.*.*`

		getLists('COMPANY_CURRENT_STATUS', filter, accessToken)
			.then(({ data: { data } }) => {
				if (data && data.length > 0) {
					setCustomerData(data[0]);
					// console.log('company data', data[0]);
				} else {
					setCustomerData([])
				}
			})
			.catch((err) => {
				setCustomerData([])
				throw err;
				// setIsloading(false)
				//   setStatus('The login detail is incorrect')
			})
	}

	// GET LEADS LIST
	const sentRequest = (value) => {

		if(customerData && customerData.customer_id.email)
		{
			const values = [];
			values['to'] = 'maciej@saljare.se';

			let content = '<table style="font-size: 14px;width:100%"><tr style="font-weight: 700">Prislista:</tr><tr></tr><tr><td style="font-weight: 600;">Business Name:</td></tr><tr><td>{{businessname}}</td></tr><tr><td style="font-weight: 600;">Contact Name:</td></tr><tr><td>{{contactname}}</td></tr><tr><td style="font-weight: 600;">Contact Mail:</td></tr><tr><td>{{contactmail}}</td></tr></table>';
			values['body'] = content;
			values['subject'] = value + ' Request';

			let params = {
				...values,
				type: "html",
				data: {
				companyname: customerData.customer_id.company_name || '-',
				businessname:customerData.industry  || '-',
				contactname: customerData.customer_id.first_name + ' ' +customerData.customer_id.last_name || '-',
				contactmail: customerData.customer_id.email || '-',
				value : value
				}
			};
			postMail(params)
			.then(({ data: { data } }) => {
				console.log(JSON.stringify(data));
			})
			.catch((err) => {
				throw err;
			});
		}
		updateUser(data.id, value)
			.then(({ data: { data } }) => {
				setStatusSent("Your request has been sent. Mail has been sent to admin.")
			})
			.catch((err) => {
				// setLeads([])
				throw err;
				// setIsloading(false)
				//   setStatus('The login detail is incorrect')
			})
	}


	return (
		<React.Fragment>
			{statusSent ? (
				<div className='mb-lg-15 alert alert-success'>
					<div className='alert-text font-weight-bold'>{statusSent}</div>
				</div>
			) : null}
			<h2 className="mb-10">Öka konvertering</h2>
			<div className="row g-5 g-xl-8">

				<div className="col-xl-4">
					<a href="#" className="card card-xxl-stretch bg-primary">

						<div className="card-body d-flex flex-column justify-content-between">

							<span className="svg-icon svg-icon-white svg-icon-2hx ms-n1 flex-grow-1">
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
									<rect x="2" y="2" width="9" height="9" rx="2" fill="black"></rect>
									<rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="black"></rect>
									<rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="black"></rect>
									<rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="black"></rect>
								</svg>
							</span>

							<div className="d-flex flex-column">
								<div className="text-white fw-bolder fs-1 mb-0 mt-5">Video</div>
								<div className="text-white fw-bold fs-6">12 700 kr</div> 
							</div>
							<div style={{ textAlign: 'right', marginBottom: 15 }}>
								<a  onClick={() => sentRequest('VIDEO')} className="btn btn-light-primary btn-shadow ms-2" style={{ height: 40, marginTop: 30 }}>Skicka förfrågan</a> 
								{/* <Button onClick={() => sentRequest('VIDEO')} variant="contained" color="primary" >
									<span className="sentrequest">Sent Request</span>
								</Button> */}
							</div>
						</div>

					</a>
				</div>
				<div className="col-xl-4">
					<a href="#" className="card card-xxl-stretch bg-warning">

						<div className="card-body d-flex flex-column justify-content-between">

							<span className="svg-icon svg-icon-white svg-icon-2hx ms-n1 flex-grow-1">
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
									<rect x="2" y="2" width="9" height="9" rx="2" fill="black"></rect>
									<rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="black"></rect>
									<rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="black"></rect>
									<rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="black"></rect>
								</svg>
							</span>

							<div className="d-flex flex-column">
								<div className="text-white fw-bolder fs-1 mb-0 mt-5">COMPETITOR</div>
								<div className="text-white fw-bold fs-6">4 900 kr/mån</div>
							</div>
							<div style={{ textAlign: 'right', marginBottom: 15 }}>

								{/* <Button onClick={() => sentRequest('COMPETITOR')} variant="contained" color="primary" >
									<span className="sentrequest">Sent Request</span>
									</Button>
								*/}

								<a onClick={() => sentRequest('COMPETITOR')}  className="btn btn-light-primary btn-shadow ms-2" style={{ height: 40, marginTop: 30 }}>Skicka förfrågan</a>

							</div>
						</div>

					</a>
				</div>
				<div className="col-xl-4">
					<a href="#" className="card card-xxl-stretch bg-success">

						<div className="card-body d-flex flex-column justify-content-between">

							<span className="svg-icon svg-icon-white svg-icon-2hx ms-n1 flex-grow-1">
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
									<rect x="2" y="2" width="9" height="9" rx="2" fill="black"></rect>
									<rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="black"></rect>
									<rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="black"></rect>
									<rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="black"></rect>
								</svg>
							</span>

							<div className="d-flex flex-column">
								<div className="text-white fw-bolder fs-1 mb-0 mt-5">Avslutstekknik</div>
								<div className="text-white fw-bold fs-6">7500 kr</div> 
							</div>
							<div style={{ textAlign: 'right', marginBottom: 15 }}>
								{/* <Button onClick={() => sentRequest('FINISHING')} variant="contained" color="primary" >
									<span className="sentrequest">Sent Request</span>
								</Button> */}
								<a onClick={() => sentRequest('COMPETITOR')}  className="btn btn-light-primary btn-shadow ms-2" style={{ height: 40, marginTop: 30 }}>Skicka förfrågan</a>

							</div>
						</div>

					</a>
				</div>
			</div>


		</React.Fragment>
	);
}

export { Boostconverstion };