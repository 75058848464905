/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react';
import { updateUser, getLists } from '../dashboard/redux/DashboardCrud'
import { useSelector } from "react-redux";
import { useLang } from '../../../_metronic/i18n/Metronici18n'

export default function Page() {
	const { data } = useSelector(({ auth }) => auth.user);
	const [report, setReport] = useState('');
	const [settings, setSettings] = useState('');
	const lang = useLang()
	useEffect(() => {

		getValues();
		// getSettings()
	}, [])


	const getValues = () => {
		let filter = `?fields=*.*.*`;

		getLists('CRM', filter)
			.then(({ data: { data } }) => {
				//console.log('customer reports', data);
				if (data) {
					setReport(data);

					//window.location.reload();
				} else {
					setReport('');
				}
			})
			.catch((err) => {
				throw err;
			});
	};

	// const getSettings = () => {
	// 	let filter = `/1?fields=*.*.*`;

	// 	getLists('SETTINGS', filter)
	// 		.then(({ data: { data } }) => {
	// 			//console.log('customer reports', data);
	// 			if (data) {
	// 				setSettings(data);

	// 				//window.location.reload();
	// 			} else {
	// 				setSettings('');
	// 			}
	// 		})
	// 		.catch((err) => {
	// 			throw err;
	// 		});
	// };

	return (
		<React.Fragment>
			<h2 className="mb-10">{lang === 'swedish' ? 'CRM - koppling' : 'CRM - connection'}</h2>

			<div className="row white-bg">
				{
					report && report.map((item) => {
						return <>
							<div className="col-xl-4" style={{ padding: 0 }}>


								<div className="crm-image">
									<img className="crm-video" src={item?.image?.data?.full_url} />
								</div>

							</div>
							<div className="col-xl-8" style={{ padding: 0 }}>
								<div className="card-white meeting-info">
									<div className="text-body">
										<div className="flex-column vid-play">
											<video className="video"
												playsInline
												muted
												controls
												src={item?.video?.data?.full_url}
											/>
										</div>
									</div>
								</div>
							</div>
						</>
					})
				}
				{/* <div dangerouslySetInnerHTML={{ __html: settings.crm_text }} >
						</div> */}
			</div>
		</React.Fragment>
	);
}

export { Page };