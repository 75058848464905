import clsx from 'clsx'
import React, { FC, useEffect, useState } from 'react'
import { HeaderUserMenu } from '../../../partials'
import { useSelector, shallowEqual } from "react-redux";
import { useLang, setLanguage } from '../../../i18n/Metronici18n'
import { Link } from 'react-router-dom'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'
import { useDispatch } from 'react-redux'
import { getUserByToken, getLangByToken } from '../../../../app/modules/auth/redux/AuthCRUD'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px'

export default function Topbar(props) {
  const { data } = useSelector(({ auth }) => auth.user);
  // const { data: langs } = useSelector(({ auth }) => auth.languages);
  const [loggeduser, setLoggedUser] = useState(data);
  const lang = useLang()
  const dispatch = useDispatch()

  useEffect(async () => {
    // const { data: user } = await getUserByToken()
    const { data: languages } = await getLangByToken()
    // dispatch(auth.actions.fulfillUser(user))
    dispatch(auth.actions.fulfillLang(languages.data))
  }, [])

  useEffect(() => {
    if (data && data.role) {
      // console.log("loggeduser?.role?.description", loggeduser?.role)
      setLoggedUser(data);
    }
  }, [data])
  useEffect(() => {
    if (dispatch) {
      // dispatch(auth.actions.requestUser())
    }
  }, [])

  const langMenu = (
    <div
      className={"lang-section"}
    // selectedKeys={[selectedLang]}
    // onClick={changeLang}
    >
      {/* {locales.map(locale => ( */}
      <>
        <Link to="#" role="img" onClick={(e) => {
          e.preventDefault();
          setLanguage('swedish')
        }}
          className={`${lang === 'swedish' ? 'isActive' : ''}`}
        >
          <img
            alt='Logo'
            className='h-20px lang-icon'
            src={"../media/flags/sweden.svg"}
          />
        </Link>
        <Link to="#" role="img" onClick={(e) => {
          e.preventDefault();
          setLanguage('english')
        }}
          className={`${lang === 'english' ? 'isActive' : ''}`}
        >
          <img
            alt='Logo'
            className='h-20px lang-icon'
            src={"../media/flags/england.svg"}
          />
        </Link>
      </>
      {/* // )) */}
      {/* } */}
    </div>
  );
  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      {/* NOTIFICATIONS */}
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        {langMenu}
        {/* {<Languages />} */}
      </div>
      <div className="vertical_line vertical_line_left"></div>

      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}

        <div className="topbar-item"
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'>
          <div className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2 profile-box">
            <img
              src={'/media/avatars/blank.png'}
              className="h-25px w-25px  rounded-circle"
            //className={'svg-icon-1 rotate-180'}
            />
            <span className="font-size-base d-none d-md-inline ml-10 mr-3">
              {
                <span className="menu_username">{loggeduser ? loggeduser?.first_name + " " + loggeduser?.last_name : null}</span>
              }
              <br />
              <span className="email">{loggeduser && loggeduser?.email}</span>

              {/* <span className="menu_username">{userDetail ? userDetail && userDetail.role?.name === "Administrator" ? 'Super Admin' : userDetail.first_name +" "+ userDetail.last_name : 'Super Admin'}</span> */}
            </span>

          </div>

        </div>


        {/* <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <img src={toAbsoluteUrl('/media/avatars/150-26.jpg')} alt='metronic' />
          <span className='menu-title' style={{paddingLeft: "10px"}}>{'Super Admin'}</span>
        </div> */}
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}

      {/* begin::Aside Toggler */}
      {/*config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
          </div>
        </div>
      ) */}
    </div>
  )
}