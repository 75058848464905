import { Action } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { put, takeLatest } from 'redux-saga/effects'
import { UserModel } from '../models/UserModel'
import { LangModel } from '../models/LangModel'
import { getUserByToken, getLangByToken } from './AuthCRUD'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  LangLoaded: '[Load Languages] Auth API',
  SetUser: '[Set User] Action',
  LangRequested: '[Request Lang] Action'
}

const initialAuthState: IAuthState = {
  user: undefined,
  accessToken: undefined,
  languages: undefined,
  swedishLang: undefined,
  englishLang: undefined
}

export interface IAuthState {
  user?: UserModel
  accessToken?: string
  languages?: LangModel
  swedishLang?: Object
  englishLang?: Object
}

interface langs {
  swedish: string;
  english: string
}

export const reducer = persistReducer(
  { storage, key: 'salescrm_user', whitelist: ['user', 'accessToken', 'languages','swedishLang','englishLang'] },
  (state: IAuthState = initialAuthState, action: ActionWithPayload<IAuthState>) => {
    switch (action.type) {
      case actionTypes.Login: {
        const accessToken = action.payload?.accessToken
        return { accessToken, user: undefined }
      }

      case actionTypes.Logout: {
        return initialAuthState
      }

      case actionTypes.UserRequested: {
        return { ...state, user: undefined }
      }

      case actionTypes.UserLoaded: {
        const user = action.payload?.user
        return { ...state, user }
      }
      case actionTypes.LangLoaded: {
        const languages: any = action.payload?.languages
        let swedishLang: any = {};
        let englishLang: any = {};
        for (let key in languages) {
          let value = languages[key]
          swedishLang[value.keyword] = value.swedish;
          englishLang[value.keyword] = value.english;
          // Use `key` and `value`
        }
        return { ...state, languages, swedishLang, englishLang }
      }
      case actionTypes.SetUser: {
        const user = action.payload?.user
        return { ...state, user }
      }

      case actionTypes.LangRequested: {
        return { ...state, languages: undefined, swedishLang: undefined, englishLang: undefined }
      }
      default:
        return state
    }
  }
)

export const actions = {
  login: (accessToken: string) => ({ type: actionTypes.Login, payload: { accessToken } }),
  register: (accessToken: string) => ({
    type: actionTypes.Register,
    payload: { accessToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: () => ({
    type: actionTypes.UserRequested,
  }),
  fulfillUser: (user: UserModel) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  fulfillLang: (languages: LangModel) => ({ type: actionTypes.LangLoaded, payload: { languages } }),
  setUser: (user: UserModel) => ({ type: actionTypes.SetUser, payload: { user } }),
  requestLang: () => ({
    type: actionTypes.LangRequested,
  }),
}

export function* saga() {

  yield takeLatest(actionTypes.Login, function* loginSaga() {
    // console.log("sagaaaa")
    yield put(actions.requestUser())
    yield put(actions.requestLang())
  })

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {

    const { data: user } = yield getUserByToken()
    // console.log("user requestedd", user)

    yield put(actions.fulfillUser(user))
  })
  yield takeLatest(actionTypes.LangRequested, function* LangRequested() {
    const { data: languages } = yield getLangByToken()
    // console.log("languages", languages)
    yield put(actions.fulfillLang(languages.data))
  })
}
