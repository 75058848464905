/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Login } from './components/Login'
import { Feedback } from './components/Feedback'

export function AuthPage() {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <Switch>
      {/*<Route path='/auth/login/:token'  component={Login} />*/}
       <Route path='/auth/login'  component={Login} />
      <Route path='/login' component={Login} />
      <Route path='/feedback/:leadid/:salesrepid/:cusid' component={Feedback} />
      <Redirect to='/auth/login' />
    </Switch>
  )
}
