/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react';
import { updateUser, getLists } from '../dashboard/redux/DashboardCrud'
import { useSelector } from "react-redux";
import { useLang } from '../../../_metronic/i18n/Metronici18n'

export default function Reports() {
	const { data } = useSelector(({ auth }) => auth.user);
	const [report, setReport] = useState('');
	const lang = useLang()

	useEffect(() => {

		if (data && data.id) {
			getCustomerReport(data);
		}

	}, [data])

	const getCustomerReport = (value, accessToken) => {
		let filter = `?filter[customer_id][eq]=${value.id}&fields=*.*.*.*`;
		getLists('CUSTOMER_REPORTS', filter)
			.then(({ data: { data } }) => {
				//console.log('customer reports', data);
				if (data && data[0] && data[0].reports && data[0].reports.data) {
					setReport(data[0].reports.data.full_url);

					//window.location.reload();
				} else {
					setReport('');
				}
			})
			.catch((err) => {
				throw err;
			});
	};


	return (
		<React.Fragment>
			<h2 className="mb-10">{lang === 'swedish' ? 'Special rapport' : 'Special report'}</h2>
			<h2 className="mb-10"></h2>
			<div className="row g-5 g-xl-8">
				{report == '' ? <div>Ingen rapport hittades</div>
					: <div className='card-white flex-column justify-center'>
						<div className="col-3 mt-50 mb-50">
							<div className="card ">
								<a href={report} target="_blank" class="btn btn-primary"><i class="bi bi-download fs-5"></i>Ladda ner</a>
							</div>
						</div>
					</div>
				}
			</div>
		</React.Fragment>
	);
}

export { Reports };