import axios from 'axios'
import { AuthModel } from '../models/AuthModel'
import { UserModel } from '../models/UserModel'
import { LangModel } from '../models/LangModel'
import { API_URL } from '../../../../_metronic/helpers/components/constant'
// const API_URL = "https://assetapi.inspirepro.co.in"

export const GET_USER_BY_accessToken_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const AUTH_LOGIN_URL = `${API_URL}/auth/authenticate`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const CURRENT_USER = `${API_URL}/users/me?fields=*.*`
export const LANGUAGES = `${API_URL}/items/languages?fields=english,swedish,keyword&limit=-1`
export const FEEDBACK = `${API_URL}/items/feedback`

// Server should return AuthModel
export function login(email: string, password: string) {

  return axios.post(AUTH_LOGIN_URL, {
    email: email,
    password: password,
    userName: email,
    type: 'account'
  })
}

// Server should return AuthModel
export function register(email: string, firstname: string, lastname: string, password: string) {
  return axios.post<AuthModel>(REGISTER_URL, {
    email,
    firstname,
    lastname,
    password,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.get<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    params: {
      email: email,
    },
  })
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.get<UserModel>(CURRENT_USER)
  // return axios.get<UserModel>(GET_USER_BY_accessToken_URL)
}

export function getLangByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.get<LangModel>(LANGUAGES)
  // return axios.get<UserModel>(GET_USER_BY_accessToken_URL)
}

export function feedback(feedback_comment: string, user: Number,lead: Number,feedback: Number,customer:Number) {

  return axios.post(FEEDBACK, {
    feedback_comment: feedback_comment,
    user: user,
    owner: user,
    lead: lead,
    feedback: feedback,
    status: 'published',
    customer: customer
  })
}


export function updatefeedback(id:Number,feedback_comment: string, user: Number,lead: Number,feedback: Number,customer:Number) {

  return axios.patch(`${FEEDBACK}/${id}`, {
    feedback_comment: feedback_comment,
    user: user,
    owner: user,
    lead: lead,
    feedback: feedback,
    status: 'published',
    customer: customer
  })
}
