
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import Discount from './discount.png';
import Phone from './phone.png';
import Meeting from './meeting.png';
import Info from './information.png';
import { Modal } from 'react-bootstrap-v5';
import ReactTags from 'react-tag-autocomplete'

export default function LIstCard({
    leads,
    localLanguage,
    modalInfo,
    setLeadStatus,
    setVisible,
    visible,
    answerModal,
    leadchat,
    answersList,


}) {
    const reactTags = useRef();

    const [statuscolors] = useState({
        OFFER: '#e10087ad',
        PHONE: '#41c4f1',
        MEETING: '#50cd89',
        INFO: '#FFD07F'
    });

    console.log("leads", modalInfo)
    return (
        <Modal
            className="modal fade"
            id="kt_modal_select_location"
            data-backdrop="static"
            tabIndex={-1}
            role="dialog"
            show={visible}
            dialogClassName="modal-lg"
            aria-hidden="true"
        // onHide={dissmissLocation}
        >
            <div className="modal-content">
                <div className="modal-header list-header" style={{ backgroundColor: modalInfo && (modalInfo.leadstatus == "" || modalInfo.leadstatus == null) ? 'transparent' : modalInfo.leadstatus == "WON" ? '#50cd89' : '#FF8080' }}>
                    <h3 className="modal-title" style={{ textAlign: 'center', width: '100%', marginLeft: 54 }}> {modalInfo && modalInfo.business_name}</h3>
                    <div className="btn btn-icon btn-sm btn-light-primary" onClick={() => setVisible(false)}>
                        <KTSVG className="svg-icon-2" path="/media/icons/duotune/arrows/arr061.svg" />
                    </div>
                </div>
                <div className="modal-body">
                    {
                        !answerModal &&
                        <div className='row g-5'>
                            <div className="col-xl-6">
                                {modalInfo && modalInfo.leadstatus == "WON" && <a style={{ height: 40 }} className="btn btn-success color-white ms-2 btnGreen btn-hover-scale" disabled="true">{localLanguage.approved}</a>}
                                {modalInfo && modalInfo.leadstatus == "LOST" && <a style={{ height: 40 }} className="btn btn-danger ms-2" disabled="true">{localLanguage.denied}</a>}
                                {/* {modalInfo && (modalInfo.leadstatus == "" || modalInfo.leadstatus == null) && <div>
                                    <a onClick={() => setLeadStatus('WON')} style={{ height: 40 }} className="btn btn-success color-white ms-2 btnGreen btn-hover-scale" disabled="true">{localLanguage.accept_lead}</a>
                                    <a onClick={() => setLeadStatus('LOST')} style={{ height: 40, color: '#ffffff !important' }} className="btn btn-danger ms-2">{localLanguage.deny_lead}</a>
                                </div>} */}
                            </div>
                            <div className="col-xl-6">
                                {modalInfo && modalInfo.status_type == "MEETING" &&
                                    <div className="rowMedium">
                                        <div className="card-icon-rounded" style={{ backgroundColor: modalInfo && modalInfo.status_type ? statuscolors[modalInfo.status_type] : '#50cd89', marginRight: 20 }}>
                                            <img src={Meeting} alt="" className="invert-img" />
                                        </div>
                                        <h5>{localLanguage.want_meeting}</h5>
                                    </div>
                                }

                                {modalInfo && modalInfo.status_type == "PHONE" &&
                                    <div className="rowMedium">
                                        <div className="card-icon-rounded" style={{ backgroundColor: modalInfo && modalInfo.status_type ? statuscolors[modalInfo.status_type] : '#50cd89', marginRight: 20 }}>
                                            <img src={Phone} alt="" className="invert-img" />
                                        </div>
                                        <h5>{localLanguage.want_called}</h5>
                                    </div>
                                }
                                {modalInfo && modalInfo.status_type == "INFO" &&
                                    <div className="rowMedium">
                                        <div className="card-icon-rounded" style={{ backgroundColor: modalInfo && modalInfo.status_type ? statuscolors[modalInfo.status_type] : '#50cd89', marginRight: 20 }}>
                                            <img src={Info} alt="" className="invert-img" />
                                        </div>
                                        <h5>{localLanguage.want_more_info}</h5>
                                    </div>
                                }
                            </div>
                        </div>

                    }
                    <div className="row g-5 g-xl-8">
                        {
                            !answerModal &&
                            <div className="col-xl-6 card-grey mb-5" style={{ backgroundColor: '#b7cfe0 !important' }}>
                                <div className='flex-row mb-8 mt-5'>
                                    <div className='card-icon-rounded-small' style={{ backgroundColor: '#50cd89' }}>
                                        <i className='bi bi-building fs-3' style={{ color: '#fff' }}></i>
                                    </div>

                                    <div className='ms-5' style={{ marginTop: -5 }}>
                                        <div className='fs-5 fw-bolder text-gray-600'>{localLanguage.business_name}</div>
                                        <a className='fs-5 fw-bold text-gray-900'>{modalInfo && modalInfo.business_name}</a>
                                    </div>
                                </div>

                                <div className='flex-row mb-8'>
                                    <div className='card-icon-rounded-small' style={{ backgroundColor: '#50cd89' }}>
                                        <i className='bi bi-activity fs-3' style={{ color: '#fff' }}></i>
                                    </div>

                                    <div className='ms-5' style={{ marginTop: -5 }}>
                                        <div className='fs-5 fw-bolder text-gray-600'>{localLanguage.lead_score}</div>
                                        <a className='fs-5 fw-bold text-gray-900'>{modalInfo && modalInfo.leadscore}</a>
                                    </div>

                                </div>

                                <div className='flex-row mb-8'>
                                    <div className='card-icon-rounded-small' style={{ backgroundColor: '#50cd89' }}>
                                        <i className='bi bi-person fs-3' style={{ color: '#fff' }}></i>
                                    </div>
                                    <div className='ms-5' style={{ marginTop: -5 }}>
                                        <div className='fs-5 fw-bolder text-gray-600'>{localLanguage.contact_name}</div>
                                        <a className='fs-5 fw-bold text-gray-900'>{modalInfo && modalInfo.contactname}</a>
                                    </div>
                                </div>

                                <div className='flex-row mb-8'>
                                    <div className='card-icon-rounded-small' style={{ backgroundColor: '#50cd89' }}>
                                        <i className='bi bi-envelope fs-3' style={{ color: '#fff' }}></i>
                                    </div>
                                    <div className='ms-5' style={{ marginTop: -5 }}>
                                        <div className='fs-5 fw-bolder text-gray-600'>{localLanguage.contact_email}</div>
                                        <a className='fs-5 fw-bold text-gray-900'>{modalInfo && modalInfo.contactmail}</a>
                                    </div>
                                </div>

                                <div className='flex-row mb-8'>
                                    <div className='card-icon-rounded-small' style={{ backgroundColor: '#50cd89' }}>
                                        <i className='bi bi-back fs-3' style={{ color: '#fff' }}></i>
                                    </div>

                                    <div className='ms-5' style={{ marginTop: -5 }}>
                                        <div className='fs-5 fw-bolder text-gray-600'>{localLanguage.lead_status}</div>
                                        <a className='fs-5 fw-bold text-gray-900'>{modalInfo && modalInfo.leadstatus ? "WON" : "NA"}</a>
                                    </div>
                                </div>

                                <div className='flex-row mb-8'>
                                    <div className='card-icon-rounded-small' style={{ backgroundColor: '#50cd89' }}>
                                        <i className='bi bi-calendar-date fs-3' style={{ color: '#fff' }}></i>
                                    </div>

                                    <div className='ms-5' style={{ marginTop: -5 }}>
                                        <div className='fs-5 fw-bolder text-gray-600'>{localLanguage.created_on}</div>
                                        <a className='fs-5 fw-bold text-gray-900'>{modalInfo && modalInfo.leaddate}</a>
                                    </div>
                                </div>
                                <div className='flex-row mb-8'>
                                    <div className='card-icon-rounded-small' style={{ backgroundColor: '#50cd89' }}>
                                        <i className='bi bi-phone fs-3' style={{ color: '#fff' }}></i>
                                    </div>

                                    <div className='ms-5' style={{ marginTop: -5 }}>
                                        <div className='fs-5 fw-bolder text-gray-600'>{localLanguage.created_phone}</div>
                                        <a className='fs-5 fw-bold text-gray-900'>{modalInfo && modalInfo.contactphone}</a>
                                    </div>
                                </div>
                                <div className='flex-row mb-8'>
                                    <div className='card-icon-rounded-small' style={{ backgroundColor: '#50cd89' }}>
                                        <i className='bi bi-phone fs-3' style={{ color: '#fff' }}></i>
                                    </div>

                                    <div className='ms-5' style={{ marginTop: -5 }}>
                                        <div className='fs-5 fw-bolder text-gray-600'>{localLanguage.position}</div>
                                        <a className='fs-5 fw-bold text-gray-900'>{modalInfo && modalInfo.customer_id?.title ? modalInfo.customer_id?.title : '-'}</a>
                                    </div>
                                </div>
                                {/* <div className='flex-row mb-8'>
                                    <div className='card-icon-rounded-small' style={{ backgroundColor: '#50cd89' }}>
                                        <i className='bi bi-phone fs-3' style={{ color: '#fff' }}></i>
                                    </div>

                                    <div className='ms-5' style={{ marginTop: -5 }}>
                                        <div className='fs-5 fw-bolder text-gray-600'>{localLanguage.position}</div>
                                        <a className='fs-5 fw-bold text-gray-900'>{'CEO'}</a>
                                    </div>
                                </div> */}
                                {leadchat && leadchat.length == 0 && modalInfo.leadcomment != null &&
                                    <div className='flex-row mb-8'>
                                        <div className='card-icon-rounded-small' style={{ backgroundColor: '#50cd89' }}>
                                            <i className='bi bi-chat fs-3' style={{ color: '#fff' }}></i>
                                        </div>

                                        <div className='ms-5' style={{ marginTop: -5 }}>
                                            <div className='fs-5 fw-bolder text-gray-600'>{localLanguage.lead_conversation}</div>
                                            <a className='fs-5 fw-bold text-gray-900'>{modalInfo && modalInfo.leadcomment}</a>
                                        </div>
                                    </div>
                                }

                            </div>
                        }
                        <div className={`${answerModal ? 'col-xl-12' : 'col-xl-6'} mb-5`}>

                            <div className='fs-5 fw-bolder text-gray-600 mb-5'>{localLanguage.history}</div>

                            {
                                // leadchat?.length > 0 && leadchat.map((item, index) => {
                                //     return (
                                //         index % 2 ?
                                //             <div className={'flex-row mb-8'} key={index} style={{ justifyContent: 'flex-end' }}>
                                //                 <div className='ms-5 chatbox'>
                                //                     <a className='fs-6 fw-bold text-gray-900'>{item.comment}</a>
                                //                 </div>
                                //                 <div className='card-icon-rounded-small' style={{ backgroundColor: '#34a7ce', padding: 5 }}>
                                //                     <img
                                //                         alt='Logo'
                                //                         className='h-20px logo'
                                //                         src={"../media/logos/logo.png"}
                                //                     />
                                //                 </div>


                                //             </div>
                                //             :
                                //             <div className={'flex-row mb-8'} key={index} >


                                //                 <div className='card-icon-rounded-small' style={{ backgroundColor: '#34a7ce', padding: 5 }}>
                                //                     <img
                                //                         alt='Logo'
                                //                         className='h-20px logo'
                                //                         src={"../media/logos/logo.png"}
                                //                     />
                                //                 </div>

                                //                 <div className='ms-5 chatbox'>
                                //                     <a className='fs-6 fw-bold text-gray-900 comnt'>{item.comment}</a>
                                //                 </div>
                                //             </div>
                                //     )
                                // })
                            }
                            {
                                // answerModal &&
                                // <>
                                //     <div className='row mb-10'>
                                //         <div className='col-lg-12 col-xl-12'>
                                //             <select
                                //                 className='form-select form-select-solid'
                                //                 name='layout-builder[layout][header][width]'
                                //                 onChange={(e) =>
                                //                     setSelectAnswer(e.target.value)
                                //                 }
                                //             >
                                //                 <option value={''}>{'Choose answer '}</option>
                                //                 {
                                //                     answersList.length > 0
                                //                     && answersList.map((item, i) => {
                                //                         return (
                                //                             <option key={i} value={item.answer}>{item.answer}</option>
                                //                         )
                                //                     })
                                //                 }
                                //             </select>
                                //         </div>
                                //     </div>
                                //     <div className='row mb-10'>
                                //         <div className='col-lg-12 col-xl-12'>
                                //             <ReactTags
                                //                 ref={reactTags}
                                //                 tags={tags}
                                //                 suggestions={suggestions}
                                //                 onDelete={onDelete}
                                //                 onAddition={onAddition}
                                //                 placeholder=""
                                //             />
                                //         </div>
                                //     </div>
                                // </>
                            }
                        </div>
                        {/* <div className={`${answerModal ? 'btm-btn j-center' : 'btm-btn'} mb-5`}>
                            {
                                answerModal ? <>
                                    <div className='ms-5'>
                                        <a onClick={() => { setAnswerModal(false) }} className="btn btn-success color-white ms-2 btnDarkBlue round" disabled="true">{localLanguage.cancel_btn}</a>
                                    </div><div className='ms-5'>
                                        <a onClick={() => { submitAnswer() }} className="btn btn-success color-white ms-2 btnDarkBlue round" disabled="true">{localLanguage.send}</a>
                                    </div></>
                                    :
                                    <>
                                        <div className='ms-5'>
                                            <a onClick={() => { setAnswerModal(true); }} className="btn btn-success color-white ms-2 btnDarkBlue" disabled="true">{localLanguage.answer}</a>
                                        </div>
                                        <div className='ms-5'>
                                            <a onClick={() => { setRating(modalInfo.lead_rating ? modalInfo.lead_rating : 0); setRatingModal(true); }} className="btn btn-success color-white ms-2 btnDarkBlue" disabled="true">{modalInfo.lead_rating ? modalInfo.lead_rating : 0}</a>
                                        </div>
                                        <div className='ms-5'>
                                            <a onClick={() => { setVisible(false); setCommentModal(true); }} className="btn btn-success color-white ms-2 btnBlue" disabled="true">{localLanguage.comment}</a>
                                        </div>
                                        <div className='ms-5'>
                                            <a onClick={() => { setVisible(false); setLoading(false); setEmailModal(true); }} className="btn btn-success color-white ms-2 btnBlue" disabled="true">{localLanguage.send_lead}</a>
                                        </div>
                                    </>
                            }
                        </div> */}
                    </div>
                </div>
            </div >
        </Modal >
    )
}
