/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState, useRef } from 'react';
import { postRep, getLists, updateRep, deleteItem, postAnswer, updateAnswer, deleteAnswer } from '../dashboard/redux/DashboardCrud';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap-v5';
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers';
import { useLang } from '../../../_metronic/i18n/Metronici18n'
import LeadsList from '../dashboard/components/LeadsList';

export default function Salesrep() {
	const { data } = useSelector(({ auth }) => auth.user);
	const [report, setReport] = useState([]);
	const [createModule, setCreateModule] = useState(false);
	const [editModule, setEditModule] = useState(false);
	const [editId, setEditId] = useState('');
	const [ansEditId, setAnsEditId] = useState('');
	const [loading, setLoading] = useState(false);

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const textInput = useRef(null);
	const { swedishLang, englishLang } = useSelector(({ auth }) => auth);
	const selectedLang = useLang()
	const [localLanguage, setLocalLanguage] = useState({})
	const [userInfo, setUserInfo] = useState({})
	const [leads_List, setLeadsList] = useState({})

	useEffect(() => {
		if (selectedLang === 'english') {
			setLocalLanguage(englishLang)
		} else {
			setLocalLanguage(swedishLang)
		}
	}, [selectedLang, swedishLang, englishLang]);

	useEffect(
		() => {
			if (data && data.id) {
				setUserInfo(data)
				getSalersrep(data);
				getLeads(data)
			}
		},
		[data]
	);

	const getSalersrep = (data) => {
		let filter = `?filter[customer_id][eq]=${data.id}&fields=*.*.*.*`;
		getLists('CUSTOME_SALES_REP', filter)
			.then(({ data: { data } }) => {
				//console.log('Sales reps', data);

				if (data) {
					setReport(data);
				} else {
					setReport('');
				}
			})
			.catch((err) => {
				throw err;
			});
	};

	const getLeads = (data) => {
		let filter = `?filter[customer_id][eq]=${data.id}&filter[salesreps][nnull]=true&fields=salesreps,leadstatus,status_type,ismobilesent,leadscore,contactname,business_name,contactmail,contactphone,leadinfo,id,customer_id.id,customer_id.first_name.customer_id.email,customer_id.title,leaddate,leadcomment,sales_reps,lead_rating&sort=-created_on`;
		getLists('LEADS', filter)
			.then(({ data: { data } }) => {
				if (data) {
					setLeadsList(data);
				}
			})
			.catch((err) => {
				throw err;
			});
	};



	const submitNew = () => {
		if (name === '') {
			alert('Please enter name');
			return false;
		}
		if (email === '') {
			alert('Please enter email');
			return false;
		}
		setLoading(true)
		if (name && email) {
			let value = {
				name: name,
				email: email,
				customer_id: data.id,
				status: 'published',
				// phone_number: phoneNumber
				//created_at: new Date()
			};
			postRep(value)
				.then(({ data: { data } }) => {
					setLoading(false)
					setName('');
					setEmail('');
					setCreateModule(false)
					getSalersrep(userInfo);
				})
				.catch((err) => {
					setLoading(false)
					setCreateModule(false)
					throw err;
				});
		}
	};

	const editRep = (value) => {
		setName(value.name);
		setEmail(value.email);
		setPhoneNumber(value.phone_number);
		setEditId(value.id);
		setEditModule(true);
	};

	const submitEdit = () => {
		if (name === '') {
			alert('Please enter name');
			return false;
		}
		if (email === '') {
			alert('Please enter email');
			return false;
		}
		if (phoneNumber === '') {
			alert('Please enter phone number');
			return false;
		}
		setLoading(true)
		if (name && email) {
			const value = {
				name: name,
				email: email,
				phone_number: phoneNumber
				//created_at: new Date()
			};
			updateRep(editId, value)
				.then(({ data: { data } }) => {
					setName('');
					setEmail('');
					setPhoneNumber('')
					setEditModule(false);
					getSalersrep(userInfo);
					setLoading(false)
				})
				.catch((err) => {
					setLoading(false)
					console.log(err);
				});
		}
	};

	const deleteItem = (id) => {

		if (window.confirm('Are you sure you want to delete this rep?')) {
			deleteItem('CUSTOME_SALES_REP', id);
			setReport(report.filter((item) => item.id !== id));
		}
	};

	return (
		<React.Fragment>

			<h2 className="mb-10">{localLanguage.seller_list}</h2>
			<div className="row g-5 g-xl-8">
				{!report ? (
					<div>{localLanguage.no_seller}</div>
				) : (
					<>
						<div className="card-white">
							<div>
								{/* begin::Header */}
								<div className="card-header border-0">
									<div className="card-toolbar" />
								</div>
								{/* end::Header */}
								<div
									className="align-items-end mb-3"
									style={{ display: 'flex', flexDirection: 'column-reverse' }}
								>
									<a
										onClick={() => {
											setCreateModule(true);
										}}
										class="btn btn-primary"
									>
										<i class="fas fa-plus fs-4 me-2" />{localLanguage.add_sales_rep_title}
									</a>
								</div>

								{/* begin::Body */}
								<div className="card-body mb-2" style={{ backgroundColor: '#f5f8fa' }}>
									<div className="tab-content">
										{/* begin::Tap pane */}
										<div className="tab-pane fade show active" id="kt_table_widget_5_tab_1">
											{/* begin::Table container */}
											<div className="table-responsive">
												{/* begin::Table */}
												<table className="table table-row-dashed table-row-gray-200 align-middle gs-0">
													{/* begin::Table head */}
													<thead>
														<tr className="border-0">
															<th className="p-0 w-50px" />
															<th className="p-0" />
															<th className="p-0" />
															<th className="p-0" />
														</tr>
													</thead>
													{/* end::Table head */}
													{/* begin::Table body */}
													<tbody>
														<tr>
															<td>
																<p className="text-dark fw-bolder mb-1 fs-6">
																</p>
															</td>
															<td>
																<span className="text-muted fw-bold d-block">
																	{localLanguage.name}
																</span>
															</td>

															<td>
																<span className="text-muted fw-bold d-block">
																	{localLanguage.email}
																</span>
															</td>
															{/* <td>
																<span className="text-muted fw-bold d-block">
																	{localLanguage.phone_number}
																</span>
															</td> */}
															<td>
																<span className="text-muted fw-bold d-block">
																</span>
															</td>
														</tr>
														{report &&
															report.map((item, index) => (
																<tr>
																	<td>
																		<p className="text-dark fw-bolder mb-1 fs-6">
																			{index + 1}
																		</p>
																	</td>
																	<td>
																		<p className="text-dark fw-bolder mb-1 fs-6">
																			{item.name}
																		</p>
																	</td>

																	<td>
																		<p className="text-dark fw-bolder mb-1 fs-6">
																			{item.email}
																		</p>
																	</td>
																	{/* <td>
																		<p className="text-dark fw-bolder mb-1 fs-6">
																			{item.phone_number}
																		</p>
																	</td> */}
																	<td>
																		<div className="flex-row">
																			<a
																				onClick={() => editRep(item)}
																				class="btn btn-light btn-hover-rise"
																			>
																				<i class="bi bi-pencil-fill text-warning fs-5 " />
																			</a>
																			<a
																				onClick={() => { deleteItem(item.id); }}
																				class="btn btn-light btn-hover-rise">
																				<i class="bi bi-trash text-danger fs-5 " />
																			</a>
																		</div>
																	</td>
																</tr>
															))}
													</tbody>
													{/* end::Table body */}
												</table>
											</div>
											{/* end::Table */}
										</div>
										{/* end::Tap pane */}
									</div>
								</div>
								{/* end::Body */}
							</div>
						</div>
					</>)}

				<div className="row g-5 g-xl-8">
					<div className="row">
						<h2 className="mb-10">{localLanguage.leads}</h2>

						{
							<LeadsList
								data={data}
								leads={leads_List}
								isDashboard={false}
								localLanguage={localLanguage}
							/>
						}
					</div>
				</div>
			</div>


			{/* start::Email modal */}

			<Modal
				className="modal fade"
				data-backdrop="static"
				tabIndex={-1}
				role="dialog"
				show={createModule}
				dialogClassName="modal-lg"
				aria-hidden="false"
			>
				<div className="modal-content">
					<div className="modal-header">
						<h3 className="modal-title" style={{ width: '100%', marginLeft: 5 }}>
							{localLanguage.add_sales_rep_title}
						</h3>
						<div className="btn btn-icon btn-sm btn-light-primary" onClick={() => setCreateModule(false)}>
							<KTSVG className="svg-icon-2" path="/media/icons/duotune/arrows/arr061.svg" />
						</div>
					</div>
					<div className="modal-body">
						<form>
							<div class="mb-10">
								<label for="exampleFormControlInput1" class="required form-label">
									{localLanguage.name}
								</label>
								<input
									ref={textInput}
									type="text"
									class="form-control form-control-solid"
									placeholder="Rep name"
									value={name}
									onChange={(e) => {
										setName(e.target.value);
									}}
								/>
							</div>
							<div class="mb-10">
								<label for="exampleFormControlInput12" class="required form-label">
									{localLanguage.email}
								</label>
								<input
									type="text"
									class="form-control form-control-solid"
									placeholder="name@example.com"
									value={email}
									onChange={(e) => {
										setEmail(e.target.value);
									}}
								/>
							</div>
							{/* <div class="mb-10">
								<label for="exampleFormControlInput12" class="required form-label">
									{localLanguage.phone_number}
								</label>
								<input
									type="text"
									class="form-control form-control-solid"
									// placeholder="name@example.com"
									value={phoneNumber}
									onChange={(e) => {
										setPhoneNumber(e.target.value);
									}}
								/>
							</div> */}
							<button disabled={loading} onClick={() => submitNew()} type="button" class="btn btn-primary">
								{loading ? (
									<span className='indicator-progress' style={{ display: 'block' }}>
										Please wait...{' '}
										<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
									</span>
								) : localLanguage.submit}
							</button>
						</form>
					</div>
				</div>
			</Modal>


			{/* Edit::Email modal */}

			<Modal
				className="modal fade"
				data-backdrop="static"
				tabIndex={-1}
				role="dialog"
				show={editModule}
				dialogClassName="modal-lg"
				aria-hidden="false"
			>
				<div className="modal-content">
					<div className="modal-header">
						<h3 className="modal-title" style={{ width: '100%', marginLeft: 5 }}>
							{' '}
							Redigera säljare
						</h3>
						<div className="btn btn-icon btn-sm btn-light-primary" onClick={() => setEditModule(false)}>
							<KTSVG className="svg-icon-2" path="/media/icons/duotune/arrows/arr061.svg" />
						</div>
					</div>
					<div className="modal-body">
						<form onSubmit={e => { e.preventDefault(); }}>
							<div class="mb-10">
								<label for="exampleFormControlInput1" class="required form-label">
									{localLanguage.name}
								</label>
								<input
									ref={textInput}
									type="text"
									class="form-control form-control-solid"
									placeholder="Rep name"
									value={name}
									onChange={(e) => {
										setName(e.target.value);
									}}
								/>
							</div>
							<div class="mb-10">
								<label for="exampleFormControlInput12" class="required form-label">
									{localLanguage.email}
								</label>
								<input
									type="text"
									class="form-control form-control-solid"
									placeholder="name@example.com"
									value={email}
									onChange={(e) => {
										setEmail(e.target.value);
									}}
								/>
							</div>
							<div class="mb-10">
								<label for="exampleFormControlInput12" class="required form-label">
									{localLanguage.phone_number}
								</label>
								<input
									type="text"
									class="form-control form-control-solid"
									// placeholder="name@example.com"
									value={phoneNumber}
									onChange={(e) => {
										setPhoneNumber(e.target.value);
									}}
								/>
							</div>
							<button disabled={loading} onClick={() => { submitEdit() }} type="button" class="btn btn-primary">
								{/* {loading ? localLanguage.submit} */}
								{loading ? (
									<span className='indicator-progress' style={{ display: 'block' }}>
										Please wait...{' '}
										<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
									</span>
								) : localLanguage.submit}
							</button>
						</form>
					</div>
				</div>
			</Modal>
		</React.Fragment>
	);
}

export { Salesrep };
