/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { feedback, updatefeedback } from '../redux/AuthCRUD'
import { getFeedback } from '../../../pages/dashboard/redux/DashboardCrud'
import { useLocation, useParams } from "react-router";
import { styled } from '@mui/material/styles';
import Rating from '@mui/material/Rating';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import PropTypes from 'prop-types';
import { FEEDBACK_STATUS } from '../../../../_metronic/helpers/components/constant'

export function Feedback() {
  const [loading, setLoading] = useState(false)
  const urlParams = useParams();
  const [feedback_comment, setValue] = useState();
  const [status, setStatus] = useState('');
  const [feedbackDetail, setfeedbackDetail] = useState('');
  const [defaultFeedback, setdefaultFeedback] = useState('');
  const [defaultFeedbackComment, setdefaultFeedbackComment] = useState('');

  const getFeedBack = (item) => {
    let response = FEEDBACK_STATUS.filter((row) => row.id === parseInt(item));
    return response?.[0] ? response[0].value : ''
  }

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    if (urlParams.leadid && urlParams.cusid && urlParams.salesrepid) {
      getFeed_back();
    }
  }, [])

  const getFeed_back = (feedback) => {
    let filter = `?filter[customer][eq]=${urlParams.cusid}&filter[lead][eq]=${urlParams.leadid}&filter[user][eq]=${urlParams.salesrepid}`
    getFeedback('FEEDBACK', filter)
      .then(({ data: { data } }) => {
        if (data?.length > 0) {
          setfeedbackDetail(data[0])
          setdefaultFeedback(data[0].feedback)
          setdefaultFeedbackComment(data[0].feedback_comment)
        } else {
          // setStatus('You have already submitted feedback.')
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  const handleSubmit = (feed_back = null) => {
    feed_back = defaultFeedback;
    if (feedbackDetail?.id) {
      updatefeedback(feedbackDetail.id, feedback_comment, urlParams.salesrepid, urlParams.leadid, feed_back, urlParams.cusid)
        .then(({ data: { data } }) => {
          setLoading(false)
          setdefaultFeedback(data.feedback)
          setdefaultFeedbackComment(data.feedback_comment)
          setStatus('Your feedback submitted successfully.')
        })
        .catch(() => {
          setLoading(false)
          setStatus('Something went wrong.')
        })
    } else {
      feedback(feedback_comment, urlParams.salesrepid, urlParams.leadid, feed_back, urlParams.cusid)
        .then(({ data: { data } }) => {
          setLoading(false)
          setdefaultFeedback(data.feedback)
          setdefaultFeedbackComment(data.feedback_comment)
          setStatus('Your feedback submitted successfully.')
        })
        .catch(() => {
          setLoading(false)
          setStatus('Something went wrong.')
        })
    }

  }

  const StyledRating = styled(Rating)(({ theme }) => ({
    '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
      color: theme.palette.action.disabled,
    },
  }));

  const customIcons = {
    1: {
      icon: <SentimentVeryDissatisfiedIcon color="error" />,
      label: 'Very Dissatisfied',
    },
    2: {
      icon: <SentimentDissatisfiedIcon color="error" />,
      label: 'Dissatisfied',
    },
    3: {
      icon: <SentimentSatisfiedIcon color="warning" />,
      label: 'Neutral',
    },
    4: {
      icon: <SentimentSatisfiedAltIcon color="success" />,
      label: 'Satisfied',
    },
    5: {
      icon: <SentimentVerySatisfiedIcon color="success" />,
      label: 'Very Satisfied',
    },
  };

  function IconContainer(props) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
  }

  IconContainer.propTypes = {
    value: PropTypes.number.isRequired,
  };

  return (
    <>
      {/* <PageLoader pageLoaderOpen={loading} setPageLoaderOpen={setLoading} /> */}
      <div className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white">

        <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">

          <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
            <div className="login-form login-signin" style={{ width: '52%' }} id="kt_login_signin_form">
              <div>
                <span className="fd_status">{status}</span>
              </div>

              {/* begin::Heading */}
              <div className='text-center mb-10 feed_tit'>
                <h1 className='text-dark mb-3'>återkopplingsformulär</h1>
              </div>

              <div className="rating">
                <StyledRating
                  name="highlight-selected-only"
                  defaultValue={defaultFeedback}
                  IconContainerComponent={IconContainer}
                  getLabelText={(value) => customIcons[value].label}
                  highlightSelectedOnly
                  onChange={(event, newValue) => setdefaultFeedback(newValue)}
                />
              </div>
              {defaultFeedback && <span className="rating-text">You have selected <strong>{getFeedBack(defaultFeedback)}</strong></span>}
              {/* begin::Heading */}
              <div className=''>
                <div className="main">
                  <form
                    className='form w-100'
                    noValidate
                    id='kt_login_signin_form'
                  >
                    {/* begin::Form group */}
                    <div className='fv-row mb-10'>
                      <label className='form-label fs-6 fw-bolder text-dark'>Add additional comment</label>
                      <textarea
                        defaultValue={defaultFeedbackComment}
                        onChange={handleChange}
                        style={{ width: '100%' }}
                        name='feedback'
                        autoComplete='off'
                        rows={5}
                      >
                      </textarea>
                    </div>
                    {/* end::Form group */}

                    {/* begin::Action */}
                    <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                      <a className="text-dark-50 text-hover-primary my-3 mr-2" id="kt_login_forgot" href="#"></a>
                      <button id="kt_login_signin_submit" onClick={() => handleSubmit()} type="button" className="btn btn-primary font-weight-bold px-9 py-4 my-3"><span>
                        {!loading && <span className='indicator-label'>Submit</span>}
                        {loading && (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}</span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}
