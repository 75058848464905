/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react';
import { updateUser, getLists } from '../dashboard/redux/DashboardCrud'
import { useSelector } from "react-redux";
import { useLang } from '../../../_metronic/i18n/Metronici18n'

export default function Page() {
	const { data } = useSelector(({ auth }) => auth.user);
	const [values, setValues] = useState([]);
	const lang = useLang()
	useEffect(() => {

		if (data && data.id) {
			getSettings();
		}

	}, [data])

	const getSettings = () => {
		let filter = `?fields=*.*.*&sort=created_on`
		getLists('SALESTRAINING', filter)
			.then(({ data: { data } }) => {
				//console.log('customer reports', data);
				if (data) {
					setValues(data);

					//window.location.reload();
				} else {
					setValues([]);
				}
			})
			.catch((err) => {
				throw err;
			});
	};
	const ReadMore = ({ children }) => {
		const text = children;
		const [isReadMore, setIsReadMore] = useState(true);
		const toggleReadMore = () => {
			setIsReadMore(!isReadMore);
		};
		return (
			<p className="text">
				{isReadMore && text ? text.slice(0, 50) : text}
				<span onClick={toggleReadMore} className="read-or-hide">
					{isReadMore ? "...read more" : " show less"}
				</span>
			</p>
		);
	};


	return (
		<React.Fragment>
			<h2 className="mb-10">{lang === 'swedish' ? 'Säljutbildning' : 'Sales training'}</h2>
			<div className="row white-bg">
				{/* <div className="card-white"> */}

				{
					values && values.map((item) => {
						return <div className="col-xl-3 sales-video">
							<h6 className="sales-title">{item.title}</h6>
							<div className="card-white meeting-info vids">
								<div className="flex-column vid-play">
									<a target="_blank" href={item?.video?.data?.full_url}>
										<div>
											<video muted className="video">
												<source src={item?.video?.data?.full_url} type="video/mp4"></source>
											</video>
										</div>
									</a>
								</div>
								{
									item.description && <div>
										<p className="description">
											<ReadMore>
												{item.description}
											</ReadMore>
										</p>
									</div>
								}

							</div>

						</div>
					})
				}
				{/* </div> */}
			</div>
		</React.Fragment>
	);
}

export { Page };