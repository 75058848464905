/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react';
import { updateUser, getLists } from '../dashboard/redux/DashboardCrud'
import { useSelector } from "react-redux";

export default function Page() {
	const { data } = useSelector(({ auth }) => auth.user);
	const [currentUser, setCurrentUser] = useState({});

	useEffect(() => {

		if (data && data.id) {
			setCurrentUser(data);
		}

	}, [data])

	return (
		<React.Fragment>

			{/* <div className="row cus-image"> */}
			<div className="col-xl-12" style={{ padding: "4%" }}>
				<div className="card-white meeting-info">

					<img src={currentUser?.avatar?.data?.full_url} style={{ width: "90%" }} className='align-self-center' />
				</div>

			</div>
			{/* </div> */}
		</React.Fragment>
	);
}

export { Page };