import React, { useEffect, useState } from 'react'
import { AsideDefault } from './components/aside/AsideDefault'
import { Footer } from './components/Footer'
import { HeaderWrapper } from './components/header/HeaderWrapper'
import { Toolbar } from './components/toolbar/Toolbar'
import { ScrollTop } from './components/ScrollTop'
import { Content } from './components/Content'
import { PageDataProvider } from './core'
import { useLocation } from 'react-router-dom'
import {
  DrawerMessenger,
  ExploreMain,
  ActivityDrawer,
  Main,
  InviteUsers,
  UpgradePlan,
} from '../partials'
import { MenuComponent } from '../assets/ts/components'
import { PageLink, PageTitle } from '../../_metronic/layout/core'
import { useStore } from 'react-redux'

const MasterLayout: React.FC = ({ children }) => {
  const location = useLocation()
  const [currentPage, setCurrentPage] = useState('');

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])
  useEffect(() => {
    if (location.pathname) {
      setCurrentPage(location.pathname.split("/")[1])
    }
  }, [location])

  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        {currentPage !== 'feedback' && <AsideDefault />}
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          {currentPage !== 'feedback' && <HeaderWrapper />}
          <div id='kt_content' className='content1 d-flex flex-column flex-column-fluid'>
            {/* <PageTitle>Lists</PageTitle> */}
            <div className='post d-flex flex-column-fluid' id='kt_post'>
              {/* <Toolbar /> */}

              <Content>{children}</Content>
            </div>
          </div>
          {/* <Footer /> */}
        </div>
      </div>

      {/* begin:: Drawers */}
      <ActivityDrawer />
      {/* <ExploreMain /> */}
      <DrawerMessenger />
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      <Main />
      <InviteUsers />
      <UpgradePlan />
      {/* end:: Modals */}
      <ScrollTop />
    </PageDataProvider>
  )
}

export { MasterLayout }
