/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { postMail, getLists, updateAPI, deleteItem } from '../dashboard/redux/DashboardCrud';
import { useSelector } from 'react-redux';
import { useLang } from '../../../_metronic/i18n/Metronici18n'
import NutureList from '../dashboard/components/NutureList';
import LeadModal from '../dashboard/components/LeadModal';
import Email from '../dashboard/components/emailto.png';
import RemoveImg from '../dashboard/components/remove.png';
import PhoneImg from '../dashboard/components/phone.png';
import PhoneImgDark from '../dashboard/components/phone_dark.png';
import ReactTags from 'react-tag-autocomplete';
import { Modal } from 'react-bootstrap-v5';
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function Salesrep() {
	const { data } = useSelector(({ auth }) => auth.user);
	const [report, setReport] = useState([]);
	const [leadStatus, setLeadStatus] = useState(false);
	const [modalInfo, setModalInfo] = React.useState([]);
	const [visible, setVisible] = React.useState(false);
	const [tags, setTags] = React.useState([]);
	const [emailModal, setEmailModal] = React.useState(false);
	const [loading, setLoading] = useState(false);
	const [mailstatus, setMailStatus] = React.useState(null);

	const [successTitle, setSuccessTitle] = useState('')
	const [successQus, setSuccessQus] = useState(false)
	const [lists, setList] = useState([])
	const { swedishLang, englishLang } = useSelector(({ auth }) => auth);
	const selectedLang = useLang()
	const [localLanguage, setLocalLanguage] = useState({})
	const [userInfo, setUserInfo] = useState({})
	const reactTags = useRef();
	const [suggestions, setSuggestion] = React.useState([]);
	const [selectedItem, setSelectedItem] = useState({});
	const [customerData, setCustomerData] = React.useState({});
	const [salesReps, setSalesReps] = useState([])
	const [temptags, setTempTags] = React.useState([]);
	const [status, setStatus] = React.useState(false);


	const onDelete = useCallback((tagIndex) => {
		setTags(tags.filter((_, i) => i !== tagIndex))
	}, [tags])

	const onAddition = useCallback((newTag) => {
		setTags([...tags, newTag])
	}, [tags])


	const getCustomerData = (data) => {
		let filter = `?filter[customer_id][eq]=${data.id}&fields=*.*.*.*`;

		getLists('COMPANY_CURRENT_STATUS', filter)
			.then(({ data: { data } }) => {
				if (data && data.length > 0) {
					setCustomerData(data[0]);
					// console.log('company data', data[0]);
				} else {
					setCustomerData([]);
				}
			})
			.catch((err) => {
				setCustomerData([]);
				throw err;
				// setIsloading(false)
				//   setStatus('The login detail is incorrect')
			});
	};

	useEffect(() => {
		if (selectedLang === 'english') {
			setLocalLanguage(englishLang)
		} else {
			setLocalLanguage(swedishLang)
		}
	}, [selectedLang, swedishLang, englishLang]);

	useEffect(
		() => {
			if (data && data.id) {
				setUserInfo(data)
				getNutureLists(data)
				getCustomerData(data)
				getSalesrepData(data);
			}
		},
		[data]
	);


	const getSalesrepData = (data) => {
		let filter = `?filter[customer_id][eq]=${data.id}&fields=id,name,email`;

		getLists('CUSTOME_SALES_REP', filter)
			.then(({ data: { data } }) => {
				if (data && data.length > 0) {
					let reps = [];
					data.map((item, index) => {
						reps.push({ id: item.id, name: item.email });
					});
					setSalesReps(data)
					//console.log('sales data',reps);
					setSuggestion(reps);
					setTags(reps);
					setTempTags(reps)
				} else {
					setSuggestion([]);
				}
			})
			.catch((err) => {
				setSuggestion([]);
				throw err;
				// setIsloading(false)
				//   setStatus('The login detail is incorrect')
			});
	};

	const getNutureLists = () => {
		let filter = `?sort=-id&filter[owner][eq]=${data.id}&fields=id,isaddphonelist,status,owner.first_name,owner.email,lead_id.id,lead_id.leadstatus,lead_id.status_type,lead_id.leadscore,lead_id.contactmail,lead_id.leaddate,lead_id.contactphone,lead_id.business_name,lead_id.leadscore,lead_id.contactname,lead_id.contactmail,lead_id.contactphone`;
		getLists('NUTURE', filter)
			.then(({ data: { data } }) => {
				//console.log('Sales reps', data);

				if (data) {
					setList(data);
				} else {
					setList([]);
				}
			})
			.catch((err) => {
				throw err;
			});
	};

	const deleteData = (id) => {
		deleteItem('NUTURE', id)
			.then(({ data: { data } }) => {
				//console.log('Sales reps', data);

				getNutureLists(userInfo)
				// setList(data);
			})
			.catch((err) => {
				throw err;
			});
	};


	const sendPhonelist = (item) => {
		let obj = {
			isaddphonelist: 'YES'
		}
		if (item.isaddphonelist === 'YES') {
			setStatus("ALREADYADD")
			return
		}
		updateAPI('NUTURE', item.id, obj)
			.then(({ data: { data } }) => {
				setStatus("SUCCESS")
				//console.log('Sales reps', data);
				getNutureLists(userInfo)
				// setList(data);
			})
			.catch((err) => {
				setStatus("FAIL")
				throw err;
			});
	};

	const handleEmailModal = (item) => {
		setSelectedItem(item)
		setEmailModal(true);
	}

	const sendEmail = () => {
		setLoading(true)
		let emailValue = [];
		if (tags) {
			tags.map((item, index) => {
				emailValue.push(item.name);
			});
		}

		if (emailValue.length == 0) {
			//alert('Please enter at least one E-Mail');
			setMailStatus('NO_REP');
			return;
		}

		const values = [];
		// values['from'] = `noreply@saljare.se`
		values['to'] = emailValue;
		let content = '<table style="style="font-size:14px;width:100%;background-color: #dfe9ee7a !important;border-radius: 10px !important;margin-bottom: 0px;padding: 15px;""><tr style="font-weight: 700">Lead Details Below:</tr><tr></tr><tr><td style="font-weight: 600;">Business Name:</td></tr><tr><td>{{businessname}}</td></tr><tr><td style="font-weight: 600;">Contact Name:</td></tr><tr><td>{{contactname}}</td></tr><tr><td style="font-weight: 600;">Contact Mail:</td></tr><tr><td>{{contactmail}}</td></tr><tr><td style="font-weight: 600;">Contact Phone:</td></tr><tr><td>{{contactphone}}</td></tr><tr><td style="font-weight: 600;">Lead Score:</td></tr><tr><td>{{leadscore}}</td></tr><tr><td style="font-weight: 600;">Lead Info:</td></tr><tr><td style="width: 80%">{{leadinfo}}</td></tr><tr></tr><tr><td style="font-weight: 600;"><a style="padding: 6px;background: #34a7ce;color: #fff!important;border-radius: 6px;margin-left: 9px;" href="https://app.saljare.se/" target="_blank" type="button">See lead</a></td></tr></table>';
		values['body'] = content;
		values['subject'] = `Lead  - ${selectedItem.lead_id.id}`;
		let params = {
			...values,
			type: "html",
			data: {
				companyname: customerData?.industry || '-',
				businessname: selectedItem.lead_id.business_name || '-',
				contactname: selectedItem.lead_id.contactname || '-',
				leadscore: selectedItem.lead_id.leadscore || '-',
				leadstatus: selectedItem.lead_id.leadstatus || '-',
				contactmail: selectedItem.lead_id.contactmail || '-',
				contactphone: selectedItem.lead_id.contactphone || '-',
				leadinfo: selectedItem.lead_id.leadinfo || '-',
				// comments: leadChatComments || '-'
			}
		};
		// console.log('params', params);
		postMail(params)
			.then(({ data: { data } }) => {
				//alert(JSON.stringif.., j .y(data));
				// setTags([]);
				//alert('Mail sent successfully');

				setMailStatus('SUCCESS');
				setEmailModal(false);
				setLoading(false);

			})
			.catch((err) => {
				setLoading(false)
				throw err;
			});

	};



	return (
		<React.Fragment>

			<h2 className="mb-10">{localLanguage.seller_list}</h2>
			<div className="row g-5 g-xl-8">
				{status == "SUCCESS" ? (
					<div className='mt-5 mb-lg-15 alert alert-success'>
						<div className='alert-text font-weight-bold'>{localLanguage.send_admin_panel_success}</div>
					</div>
				) : null}
				{status == "FAIL" ? (
					<div className='mt-5 mb-lg-15 alert alert-danger'>
						<div className='alert-text font-weight-bold'>{localLanguage.send_admin_panel_fail}</div>
					</div>
				) : null}
				{status == "ALREADYADD" ? (
					<div className='mt-5 mb-lg-15 alert alert-danger'>
						<div className='alert-text font-weight-bold'>{localLanguage.send_admin_panel_alreadyadd}</div>
					</div>
				) : null}
				{!report ? (
					<div>{localLanguage.no_seller}</div>
				) : (
					<>
						<div className="card-white">
							{successQus &&
								<div class="toast show" role="alert" style={{ position: 'absolute', top: 80, right: 20 }} aria-live="assertive" aria-atomic="true">
									<div class="toast-header">
										<strong class="me-auto"></strong>
										<button type="button" onClick={() => setSuccessQus(false)} class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
									</div>
									<div class="toast-body ">
										<h6 class="alert alert-success">{successTitle}</h6>
									</div>
								</div>
							}
							<div>
								{/* begin::Header */}
								<div className="card-header border-0">
									<div className="card-toolbar" />
								</div>
								{/* end::Header */}

								<div className="row">
									{
										<NutureList
											leads={lists}
											localLanguage={localLanguage}
											EmailImg={Email}
											setModalInfo={setModalInfo}
											setVisible={setVisible}
											RemoveImg={RemoveImg}
											deleteItem={deleteData}
											PhoneImg={PhoneImg}
											PhoneImgDark={PhoneImgDark}
											sendPhonelist={sendPhonelist}
											openEmailModal={handleEmailModal}
											setSelectedItem={setSelectedItem}
										/>
									}
								</div>
							</div>
						</div>
					</>)}
			</div>
			{
				<LeadModal
					setLeadStatus={setLeadStatus}
					localLanguage={localLanguage}
					EmailImg={Email}
					modalInfo={modalInfo}
					setVisible={setVisible}
					visible={visible}
				/>
			}
			{/* start::Email modal */}

			<Modal
				className="modal fade"
				id="kt_modal_select_location"
				data-backdrop="static"
				tabIndex={-1}
				role="dialog"
				show={emailModal}
				dialogClassName="modal-lg"
				aria-hidden="true"
			>

				<div className="modal-content">
					<div className="modal-header">
						<h3 className="modal-title" style={{ width: '100%', marginLeft: 5 }}> Mail to</h3>
						<div className="btn btn-icon btn-sm btn-light-primary" onClick={() => setEmailModal(false)}>
							<KTSVG className="svg-icon-2" path="/media/icons/duotune/arrows/arr061.svg" />
						</div>
					</div>
					<div className="modal-body">
						<ReactTags
							ref={reactTags}
							tags={tags}
							suggestions={suggestions}
							onDelete={onDelete}
							onAddition={onAddition}
							placeholder="Ex: abc@gmail.com, xyz@gmail.com"
						/>
						{mailstatus == "NO_REP" ? (
							<div className='mt-5 mb-lg-15 alert alert-danger'>
								<div className='alert-text font-weight-bold'>{localLanguage.valid_sales_rep}</div>
							</div>
						) : null}
						{mailstatus == "SUCCESS" ? (
							<div className='mt-5 mb-lg-15 alert alert-success'>
								<div className='alert-text font-weight-bold'>{localLanguage.mail_sent}</div>
							</div>
						) : null}
					</div>
					<div className="modal-footer">
						<a disabled={loading} onClick={() => sendEmail()} className="btn btn-primary">
							{loading && (
								<CircularProgress className={"progressbar"} />
							)}{localLanguage.send}</a>

					</div>
				</div>

			</Modal>
		</React.Fragment>
	);
}

export { Salesrep };
