/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState, useRef } from 'react';
import { postRep, getLists, updateRep, deleteItem, postAnswer, updateAnswer, deleteAnswer } from '../dashboard/redux/DashboardCrud';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap-v5';
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers';
import { useLang } from '../../../_metronic/i18n/Metronici18n'

export default function Salesrep() {
	const { data } = useSelector(({ auth }) => auth.user);
	const [report, setReport] = useState([]);
	const [createModule, setCreateModule] = useState(false);
	const [editModule, setEditModule] = useState(false);
	const [editId, setEditId] = useState('');
	const [ansEditId, setAnsEditId] = useState('');
	const [loading, setLoading] = useState(false);

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const textInput = useRef(null);
	const { swedishLang, englishLang } = useSelector(({ auth }) => auth);
	const selectedLang = useLang()
	const [localLanguage, setLocalLanguage] = useState({})
	const [userInfo, setUserInfo] = useState({})

	useEffect(() => {
		if (selectedLang === 'english') {
			setLocalLanguage(englishLang)
		} else {
			setLocalLanguage(swedishLang)
		}
	}, [selectedLang, swedishLang, englishLang]);

	useEffect(
		() => {
			if (data && data.id) {
				setUserInfo(data)
				getFeedbacks(data);
			}
		},
		[data]
	);

	const getFeedbacks = (data) => {
		let filter = `?filter[customer][eq]=${data.id}&fields=*.*.*.*`;
		getLists('FEEDBACK', filter)
			.then(({ data: { data } }) => {
				//console.log('Sales reps', data);

				if (data) {
					setReport(data);
				} else {
					setReport('');
				}
			})
			.catch((err) => {
				throw err;
			});
	};



	const deleteItem = (id) => {

		if (window.confirm('Are you sure you want to delete this rep?')) {
			deleteItem('CUSTOME_SALES_REP', id);
			setReport(report.filter((item) => item.id !== id));
		}
	};

	return (
		<React.Fragment>

			<h2 className="mb-10">{localLanguage.feedback}</h2>
			<div className="row g-5 g-xl-8">
				{!report ? (
					<div>{localLanguage.feedback}</div>
				) : (
					<>
						<div className="card-white">
							<div>
								{/* begin::Header */}
								<div className="card-header border-0">
									<div className="card-toolbar" />
								</div>
								{/* end::Header */}
								{/* <div
									className="align-items-end mb-3"
									style={{ display: 'flex', flexDirection: 'column-reverse' }}
								>
									<a
										onClick={() => {
											setCreateModule(true);
										}}
										class="btn btn-primary"
									>
										<i class="fas fa-plus fs-4 me-2" />{localLanguage.add_sales_rep_title}
									</a>
								</div> */}

								{/* begin::Body */}
								<div className="card-body mb-2" style={{ backgroundColor: '#f5f8fa' }}>
									<div className="tab-content">
										{/* begin::Tap pane */}
										<div className="tab-pane fade show active" id="kt_table_widget_5_tab_1">
											{/* begin::Table container */}
											<div className="table-responsive">
												{/* begin::Table */}
												<table className="table table-row-dashed table-row-gray-200 align-middle gs-0">
													{/* begin::Table head */}
													<thead>
														<tr className="border-0">
															<th className="p-0 w-50px" />
															<th className="p-0" />
															<th className="p-0" />
															<th className="p-0" />
															<th className="p-0" />
														</tr>
													</thead>
													{/* end::Table head */}
													{/* begin::Table body */}
													<tbody>
														<tr>
															<td>
																<p className="text-dark fw-bolder mb-1 fs-6">
																</p>
															</td>
															<td>
																<span className="text-muted fw-bold d-block">
																	{localLanguage.feedback}
																</span>
															</td>

															<td>
																<span className="text-muted fw-bold d-block">
																	{localLanguage.leads} {localLanguage.business_name}
																</span>
															</td>
															<td>
																<span className="text-muted fw-bold d-block">
																	{localLanguage.leads} {localLanguage.contact_name}
																</span>
															</td>
														</tr>
														{report &&
															report.map((item, index) => (
																item.feedback_comment &&
																<tr>
																	<td>
																		<p className="text-dark fw-bolder mb-1 fs-6">
																			{index + 1}
																		</p>
																	</td>
																	<td>
																		<p className="text-dark fw-bolder mb-1 fs-6">
																			{item.feedback_comment}
																		</p>
																	</td>

																	<td>
																		<p className="text-dark fw-bolder mb-1 fs-6">
																			{item?.lead?.business_name}
																		</p>
																	</td>

																	<td>
																		<p className="text-dark fw-bolder mb-1 fs-6">
																			{item?.lead?.contactname}
																		</p>
																	</td>
																</tr>
															))}
													</tbody>
													{/* end::Table body */}
												</table>
											</div>
											{/* end::Table */}
										</div>
										{/* end::Tap pane */}
									</div>
								</div>
								{/* end::Body */}
							</div>
						</div>
					</>)}
			</div>

		</React.Fragment>
	);
}

export { Salesrep };
