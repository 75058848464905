/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Modal } from 'react-bootstrap-v5';
import { KTSVG } from '../../../_metronic/helpers';

import Card from './components/Card';
import BorderedCard from './components/BorderedCard';
import BorderedCard_manual from './components/BorderedCard_manual';

import Discount from './components/discount.png';
import Phone from './components/phone.png';
import SMS from './components/sms.png';
import Meeting from './components/meeting.png';
import Info from './components/information.png';
import moment from "moment";
import { getLists, updateLeads, sendSms } from './redux/DashboardCrud'
import { useSelector } from "react-redux";
import { useLang } from '../../../_metronic/i18n/Metronici18n'
import {
	Chart,
	ArgumentAxis,
	ValueAxis,
	AreaSeries,
	Title,
	Legend
} from '@devexpress/dx-react-chart-material-ui';
import { ArgumentScale, Animation } from '@devexpress/dx-react-chart';
import { scalePoint } from 'd3-scale';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LeadsList from './components/LeadsList';
import { gapi } from "gapi-script";
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import _ from "lodash";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { formGroupClasses } from '@mui/material';


// const CLIENT_ID = '1040969583552-2vrsbckmn343pt26bn0p544aam1rgakg.apps.googleusercontent.com';
const CLIENT_ID = '497233900907-6i09qf6mcbund0423c3n2aegkbnosgi4.apps.googleusercontent.com';
const SECRET = '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';
const calendarID = 'en.indian#holiday@group.v.calendar.google.com';
// const apiKey = 'AIzaSyAci3Fahb0DMVQ3gQUbcQwrkOBH5SP9fFI';
const apiKey = 'AIzaSyDZrvZ65vPaBvehWp8jkHQhWv6-BGbGlMk';
const refresh_token = '1//0gosKOCufILTlCgYIARAAGBASNwF-L9IraMW3jUtoxXO1aNmVGVHBbTtNztsXbGhavtGDz1NyCRhGJHIZgG4RDB3QawyzttG2Ofw';
const gaaccessToken = 'ya29.a0AfB_byAMO2tw3XggnTakn6fJDBfOa4abtrSDKX5c16s9yM1vxc9qu25k2TP2FeaBxt_cUgndFJ6GK8O2n7DpmnrRpHdGiSg6yhKyupcWrqN21XtzDmab8Rg4C_HhBZ6JjYmlirkUsgfCnpXYMCAG-Ce7ZNcDc3bykUNUaCgYKAfQSARESFQGOcNnCjwZg04J1SNowL8ZF56q_Cw0171';
const SCOPES = "https://www.googleapis.com/auth/calendar";
const discoveryDocs = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"];


export default function DashboardWrapper() {

	const reactTags = useRef();
	const intl = useIntl();
	const [leads, setLeads] = React.useState([]);
	const [upcomingleads, setUpcomingleads] = React.useState([]);

	const [modalInfo, setModalInfo] = React.useState([]);
	const [customerData, setCustomerData] = React.useState({})
	const [visible, setVisible] = React.useState(false);
	const { data } = useSelector(({ auth }) => auth.user);
	const [access_Token, setAccessToken] = useState();
	const [last30Days, setLast30Days] = useState([]);
	const [leadComment, setLeadComment] = useState();
	const [leadStatus, setLeadStatus] = useState();
	const [totalLeads, setTotalLeads] = useState([]);
	const [filterDate, setFilterDate] = useState(moment().format('YYYY-MM-DD'));
	const [currentUser, setCurrentUser] = useState({});
	const [leadchat, setLeadChat] = useState([]);
	const [leadChatComments, setLeadChatComments] = useState('');
	const [commentBox, setCommentbox] = useState(false);

	const [emailModal, setEmailModal] = React.useState(false);
	const [manualMeeting, setisManualMeeting] = React.useState(null);
	const [mailstatus, setMailStatus] = React.useState(null);
	const [report, setReport] = useState('');
	const [statusimages] = useState({
		"OFFER": Discount,
		"PHONE": Phone,
		"MEETING": Meeting,
		"INFO": Info
	})
	const [statuscolors] = useState({
		"OFFER": '#4E9F3D',
		"PHONE": '#41c4f1',
		"MEETING": '#4E9F3D',
		"INFO": '#FFD07F'
	});

	const Colors = {
		0: "#4E9F3D",
		1: "#41c4f1",
		2: "#faad14",
		3: "#722ed1",
		4: "#ff7d0e",
		5: "#13c2c2",
		6: "#eb2f96",
	}
	const [leadsCount, setLeadsCount] = useState(0);
	const todayDate = moment().format('YYYY-MM-DD');
	const selectedLang = useLang()
	const { swedishLang, englishLang } = useSelector(({ auth }) => auth);
	const [localLanguage, setLocalLanguage] = useState({});
	const [areaData, setAreaData] = useState([]);
	const [value, setValue] = React.useState(0);
	const [acceptedCount, setAcceptedCount] = useState(0);
	const [notAcceptedCount, setNotAcceptedCount] = useState(0);
	const [newRecords, setNewRecords] = useState(0);
	const [leadsFilter, setleadsFilter] = useState([]);
	const [eventsList, setEventsList] = useState([]);
	const [eventsListManual, setEventsListManual] = useState([]);

	const [showSmsModal, setshowSmsModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [tags, setTags] = React.useState([]);
	const [temptags, setTempTags] = React.useState([]);
	const [suggestions, setSuggestion] = React.useState([]);
	const [salesReps, setSalesReps] = useState([])
	const [feedBackLists, setFeedBackLists] = React.useState([]);
	const [answersList, setAnswersList] = useState([])
	const [googleEvent, setGoogleEvent] = useState('');
	const [totalnuture, settotalnuture] = useState(0);
	const [confirmedmeeting, setconfirmedmeeting] = useState([]);
	const [showMeeting, setshowMeeting] = useState(false);
	const [meetingStartDate, setMeetingStartDate] = useState(null);

	const handleChange = (event, newValue) => {
		setValue(newValue);
		getLeadsList(currentUser, newValue)
		getLeadsOngoing(data)

	};


	useEffect(() => {
		if (selectedLang === 'english') {
			setLocalLanguage(englishLang)
		} else {
			setLocalLanguage(swedishLang)
		}
	}, [selectedLang, swedishLang, englishLang]);



	useEffect(() => {
		if (data && data.id && gapi) {
			setCurrentUser(data)
			getCustomerData(data)
			getLeadsListFilter(data)
			getLeadsList(data)
			getLeadsOngoing(data)
			// getLeadsTodayCount(data)
			//getCustomerReport(data)
			getUpcomingLeadsList(data, filterDate);
			getSettings();
			getNutureLists()
			getFeedbackData(data)
			getAnswers(data)
		}

	}, [data, gapi]);

	const getNutureLists = () => {
		let filter = `?sort=-id&filter[owner][eq]=${data.id}&meta=total_count,result_count,filter_count&fields=id`;
		getLists('NUTURE', filter)

			.then(({ data: { meta } }) => {
				settotalnuture(meta.filter_count)
			})
			.catch((err) => {
				settotalnuture(0)
				throw err;
			});
	};


	const getAnswers = () => {
		let filter = `?sort=-id`;
		getLists('ANSWERS', filter)
			.then(({ data: { data } }) => {
				//console.log('Sales reps', data);

				if (data) {
					setAnswersList(data);
				} else {
					setAnswersList([]);
				}
			})
			.catch((err) => {
				throw err;
			});
	};

	const getManualEventsData = (current_user, leadFilterData) => {
		let filter = `?filter[customer_id][eq]=${current_user.id}&filter[lead][nnull]=&fields=customer_id,event_status,id,lead,meeting_end,meeting_start,salesreps,lead.business_name,lead.id,lead.status_type,lead.contactname,lead.leaddate`;
		getLists('EVENTS_LIST', filter)
			.then(({ data: { data } }) => {
				if (data && data.length > 0) {
					let events = [];
					data.forEach((obj) => {
						if (obj.lead !== null) {
							events.push(obj)
						}
					})
					setEventsListManual(events)
					loadGoogleEvents(gapi, leadFilterData, events);
				} else {
					loadGoogleEvents(gapi, leadFilterData, []);
				}
			})
			.catch((err) => {
				throw err;
				// setIsloading(false)
				//   setStatus('The login detail is incorrect')
			});
	};

	const getFeedbackData = (data) => {
		let filter = `?filter[customer][eq]=${data.id}&fields=id,feedback,feedback_comment,lead,user`;
		getLists('FEEDBACK', filter)
			.then(({ data: { data } }) => {
				if (data && data.length > 0) {
					setFeedBackLists(data)
				}
			})
			.catch((err) => {
				throw err;
			});
	};

	const loadGoogleEvents = (gapi, leadsData = [], manualleads = []) => {
		gapi.load('client:auth2', () => {

			gapi.client.init({
				apiKey: apiKey,
				clientId: CLIENT_ID,
				discoveryDocs: discoveryDocs,
				scope: SCOPES
			});



			gapi.client.load('calendar', 'V3', () => {

				let payloadForAccessToken = {
					grant_type: 'refresh_token',
					refresh_token: refresh_token,
					client_id: CLIENT_ID,
					client_secret: SECRET,
				};

				axios
					.post(`https://oauth2.googleapis.com/token`, payloadForAccessToken, {
						headers: {
							'Content-Type': 'application/json;',
						},
					})
					.then((res) => {
						return res.data;
					})
					.then((res) => {
						localStorage.setItem("google_access_token", res.access_token)
						setAccessToken(res.access_token)
						getEvents(gapi, res.access_token, leadsData, manualleads)
					})
			});


		})
	}

	const getEvents = (gapi, token, leadsData, manualleads) => {
		// console.log("get eventsssss", data)
		fetch(
			`https://www.googleapis.com/calendar/v3/calendars/primary/events?key=${apiKey}&orderBy=startTime&singleEvents=true&fields=items(id),items(description),items(attendees/email),items(attendees/responseStatus),items(start),items(end),items(summary),items(status),items(htmlLink),items(end/dateTime),items(start/dateTime),items(end/timeZone),items(start/timeZone),items(organizer/email)`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		)
			.then((res) => {
				// Check if unauthorized status code is return open sign in popup
				if (res.status !== 401) {
					return res.json();
				} else {
					//    localStorage.removeItem("access_token");

					//    openSignInPopup();
				}
			})
			.then((result) => {
				if (result?.items) {
					let events = [];
					let confirmedMeetings = [];
					if (result?.items?.length > 0) {
						result?.items.forEach((item) => {
							// console.log("item.description", item.description)
							if (!item.description) {
								return
							}
							let leadData = JSON.parse(item.description)
							let lead_data = leadsData.map((item) => item.id);
							if ((item?.organizer?.email === 'direktreklammote@gmail.com' && leadData.customer === currentUser.id && lead_data.includes(leadData.lead))) {
								let responseStatus = []
								const accepted_attendees = item.attendees.filter(item => item.responseStatus === 'accepted')
								const declined_attendees = item.attendees.filter(item => item.responseStatus === 'declined')
								const unconfirmed_attendees = item.attendees.filter(item => item.responseStatus === "needsAction")
								item.attendees.forEach((obj) => {
									// if (obj?.email === 'gmahalakshmibe@gmail.com') {
									item.event_status = obj.responseStatus;
									responseStatus.push(obj.responseStatus)
									// }
								})
								events.push(item)
								item.totalcount = item.attendees.length;
								item.accepted_attendees = accepted_attendees;
								item.declined_attendees = declined_attendees;
								item.unconfirmed_attendees = unconfirmed_attendees;
								if (accepted_attendees?.length === item.attendees?.length) {
									item.current_event_status = 'confirmed'
									confirmedMeetings.push(item)
								}
							}
						})
						events = events.sort((a, b) => new Date(a.start.dateTime) - new Date(b.end.dateTime));
						// leadsData.forEach((obj) => {
						// if (meeting.includes(obj.id)) {
						// 	leadsconfirmedMeeting.push(obj)
						// }
						// })

						// setconfirmedmeeting(confirmedMeetings)

						let leadsconfirmedMeeting = []
						let eventslist = [];
						// events.forEach((obj) => {
						// 	if (obj.description) {
						// 		let leadid = JSON.parse(obj.description).lead;
						// 		leadsData.forEach((obj1) => {
						// 			if (leadid === obj1.id) {
						// 				eventslist.push(obj)
						// 			}
						// 		})
						// 	}
						// })
						// console.log("evetslist", eventslist)
						let meeting = confirmedMeetings.map((obj) => JSON.parse(obj.description).lead);

						setEventsList(events)

						// let leadsconfirmedMeeting = leadsData.filter((row) => meeting.indexOf('' + row.id) !== -1)
						// console.log("meeting", meeting)
						leadsData.forEach((obj) => {
							if (meeting.includes(obj.id)) {
								leadsconfirmedMeeting.push(obj)
							}
						})
						manualleads.forEach((obj) => {
							obj.business_name = obj.lead.business_name
							obj.contactname = obj.lead.contactname
							obj.id = obj.lead.id;
							obj.leaddate = obj.lead.leaddate;
							obj.status_type = obj.lead.status_type;
						})

						let manualconfirmed = manualleads.filter(x => x.event_status === 'confirmed')
						let meeting_confirmed = [...leadsconfirmedMeeting, ...manualconfirmed]

						setconfirmedmeeting(meeting_confirmed)
						leadsconfirmedMeeting.forEach((item) => {
							item.year = item.leaddate ? moment(item.leaddate).format('YYYY') : 'Other'
							item.year_month = item.leaddate ? moment(item.leaddate).format('YYYY-MM') : 'Other'
						})
						let yearbyleads = _(leadsconfirmedMeeting)
							.groupBy('year_month')
							.map(group => ({
								year: group[0]?.year_month,
								"value": Math.round((group.length / events.length) * 100),
							}))
							.value();
						// 	console.log("yearbyleads", yearbyleads)
						// 	yearbyleads=[
						// 		{year: '2023-10', value: 10},
						// 		{year: '2023-11', value: 7},
						// 		{year: '2023-12', value: 5},
						// 		{year: '2023-13', value: 3},
						// ]
						setAreaData(yearbyleads)

						//    setEvents(formatEvents(data.items));
					}
				}
			});

		// const request = gapi.client.calendar.events.list({
		// 	'calendarId': 'primary',
		// 	// 'sendUpdates': 'all',
		// 	// "orderBy": "startTime",
		// 	'sendNotifications': true,
		// 	"showDeleted": false,
		// 	// "q": "gmahalakshmibe@gmail.com",
		// 	"fields": "items(attendees/email),items(attendees/responseStatus),items(start),items(end),items(summary),items(status),items(htmlLink),items(end/dateTime),items(start/dateTime),items(end/timeZone),items(start/timeZone),items(organizer/email)"
		// }).then((res) => {
		// 	console.log("responseeeeeeee", res.result.items)
		// 	let events = [];
		// 	if (res?.result?.items?.length > 0) {
		// 		res?.result?.items.forEach((item) => {
		// 			if (item?.organizer?.email === 'direktreklammote@gmail.com') {
		// 				item.attendees.forEach((obj) => {
		// 					// if (obj?.email === 'gmahalakshmibe@gmail.com') {
		// 					item.event_status = obj.responseStatus;
		// 					// }
		// 				})
		// 				events.push(item)
		// 			}
		// 		})
		// 		events = events.sort((a, b) => new Date(a.start.dateTime) - new Date(b.start.dateTime));
		// 		setEventsList(events)
		// 	}

		// }).catch((err) => {
		// 	console.log("list err", err)

		// });
	}

	// console.log(confirmedmeeting, "confirmed meee", eventsListManual);

	// useEffect(() => {
	// 	if (confirmedmeeting && eventsListManual) {
	// 		let manualconfirmed = eventsListManual.filter(x => x.event_status === 'confirmed')
	// 		let meeting_confirmed = [...confirmedmeeting, ...manualconfirmed]
	// 		console.log("manualconfirmed", meeting_confirmed)
	// 		// setconfirmedmeeting(meeting_confirmed)
	// 	}
	// }, [confirmedmeeting, eventsListManual]);

	useEffect(() => {
		if (leadStatus != '') {
			updateLeadData();
		}
	}, []);

	useEffect(() => {
		let comnts = '';
		setLeadChatComments('');
		if (leadchat) {
			comnts += '<ul>';
			leadchat.map((item, index) => {
				index % 2 ?
					comnts += '<li style="list-style-position: inside;">' + (item.comment) + '</li>' :
					comnts += '<li>' + (item.comment) + '</li>'

			});
			comnts += '</ul>';
			setLeadChatComments(comnts);

		}
	}, [leadchat]);


	const getCustomerData = (data, accessToken) => {
		let filter = `?filter[customer_id][eq]=${data.id}&fields=*.*.*.*`

		getLists('COMPANY_CURRENT_STATUS', filter, accessToken)
			.then(({ data: { data } }) => {
				if (data && data.length > 0) {
					setCustomerData(data[0]);
					// console.log('company data', data[0]);
				} else {
					setCustomerData([])
				}
			})
			.catch((err) => {
				setCustomerData([])
				throw err;
				// setIsloading(false)
				//   setStatus('The login detail is incorrect')
			})
	}

	const updateLeadData = () => {

		let param = {
			leadcomment: leadComment,
		}
		if (leadStatus) {
			param.leadstatus = leadStatus;
		}
		if (leadComment) {
			param.leadcomment = leadComment;

			/*let params = {
				comment : leadComment,
				lead : modalInfo.id
			}
			postChat(params);*/

		}
		//if (!leadStatus && !leadComment) {
		if (!leadStatus) {
			return false;
		}

		updateLeads(modalInfo.id, param)
			.then(({ data: { data } }) => {
				setModalInfo([])
				setLeadComment('')
				getLeadsList(currentUser);
				setCommentbox(false);
			})
			.catch((err) => {
				setLeadComment('')
				setLeadStatus('')
				throw err;
			})
	}

	const getLeadsOngoing = (data,) => {
		setleadsFilter([])
		let fil = ''
		fil = `&filter[leadstatus][eq]=WON`

		let filter = `?filter[customer_id][eq]=${data.id}${fil}&fields=*.*.*&sort=-created_on&limit=-1`

		getLists('LEADS', filter)
			.then(({ data: { data } }) => {
				if (data && data.length > 0) {
					setVisible(false)
					setleadsFilter(data);
				}
			})
			.catch((err) => {
				setLeads([])
				setVisible(false)

				throw err;
				// setIsloading(false)
				//   setStatus('The login detail is incorrect')
			})
	}

	// GET LEADS LIST
	const getLeadsList = (data, status) => {
		setleadsFilter([])
		let fil = ''
		if (status === 1) {
			fil = `&filter[leadstatus][null]=`
		} else if (status === 2) {
			fil = `&filter[leadstatus][eq]=WON`
		} else if (status === 3) {
			fil = `&filter[leadstatus][eq]=LOST`
		}
		let filter = `?filter[customer_id][eq]=${data.id}${fil}&fields=*.*.*&sort=-created_on&limit=-1`

		getLists('LEADS', filter)
			.then(({ data: { data } }) => {
				if (data && data.length > 0) {
					setVisible(false)

					let new_records = []
					data.forEach((item) => {
						if (item.isread === false) {
							new_records.push(item)
						}
					})
					// console.log("newrecorddd", new_records)
					// console.log("filter recordd", data)
					setNewRecords(new_records)
				}
			})
			.catch((err) => {
				setLeads([])
				setVisible(false)

				throw err;
				// setIsloading(false)
				//   setStatus('The login detail is incorrect')
			})
	}
	// GET LEADS LIST
	const getLeadsListFilter = (current_user) => {
		let filter = `?filter[customer_id][eq]=${current_user.id}&fields=*.*.*&sort=-created_on&limit=-1`

		getLists('LEADS', filter)
			.then(({ data: { data } }) => {
				if (data && data.length > 0) {
					setVisible(false)

					setLeads(data);
					///For chart
					// data.forEach((item) => {
					// 	item.year = item.created_on ? moment(item.created_on, 'YYYY-MM-DD').format('YYYY') : ''
					// 	// 	rents.agreement = item.agrement_info_no;
					// 	// 	rents.year = rents.to ? moment(rents.to, 'YYYY-MM-DD').format('YYYY') : ''
					// 	//   rents_upcoming.push(item.upcomingrents)
					//   })

					// data.forEach((item) => {
					// 	item.year = item.leaddate ? moment(item.leaddate).format('YYYY') : 'Other'
					// 	item.year_month = item.leaddate ? moment(item.leaddate).format('YYYY-MM') : 'Other'
					// })
					// const yearbyleads = _(data)
					// 	.groupBy('year')
					// 	.map(group => ({
					// 		year: group[0]?.year,
					// 		"value": group.length,
					// 	}))
					// 	.value();
					// setAreaData(yearbyleads)
					// console.log("yearbyleads", yearbyleads)

					const total = _(data)
						.groupBy('leadstatus')
						.map(group => ({
							name: group[0]?.leadstatus ? group[0]?.leadstatus === 'WON' ? 'Godkända' : 'Nekade' : 'Pågående',
							"value": group.length,
							"color": group[0]?.leadstatus ? group[0]?.leadstatus === 'WON' ? '#41c4f1' : '#3f4254' : '#4E9F3D',
						}))
						.value();
					const currentDate = new Date();

					const currentDateTime = currentDate.getTime();
					// console.log(currentDate.getDate(), "x.leaddate", currentDate)

					const last30DaysDate = new Date(currentDate.setDate(currentDate.getDate() - 30));
					const last30DaysDateTime = last30DaysDate.getTime();

					const last30DaysList = data.filter(x => {
						const elementDateTime = new Date(x.created_on).getTime();
						// console.log("elementDateTime",elementDateTime)
						// console.log("last30DaysDateTime",last30DaysDateTime)

						if (elementDateTime <= currentDateTime && elementDateTime > last30DaysDateTime) {
							return true;
						}
						return false
					}).sort((a, b) => {
						return new Date(b.datum) - new Date(a.datum);
					});

					const last30Days_List = _(data)
						.groupBy('leadstatus')
						.map(group => ({
							name: group[0]?.leadstatus ? group[0]?.leadstatus === 'WON' ? 'accepted' : 'denied' : 'ongoing',
							"value": group.length
						}))
						.value();
					//  console.log('last30days',last30Days_List)
					if (last30Days_List?.length > 0) {
						last30Days_List.forEach((item) => {
							if (item.name === 'accepted') {
								setAcceptedCount(item.value)
							} else if (item.name === 'denied') {
								setNotAcceptedCount(item.value)
							}
						})
					}
					setLast30Days(last30DaysList)
					setTotalLeads(total)
					// setYearWiseMeeting(yeargroups)
					// setMonthlyWiseMeeting(monthsgroups)
					// console.log('groups1', monthsgroups);
					getManualEventsData(current_user, data);


				} else {
					setLeads([])
					loadGoogleEvents(gapi, []);

				}

			})
			.catch((err) => {
				loadGoogleEvents(gapi, []);

				setLeads([])
				setVisible(false)

				throw err;
				// setIsloading(false)
				//   setStatus('The login detail is incorrect')
			})
	}

	const getLeadsTodayCount = (data) => {

		let filter = `?filter[customer_id][eq]=${data.id}&meta=result_count%2Cfilter_count&filter[created_on][contains]=${todayDate}&fields=id`

		getLists('LEADS', filter)
			.then(({ data: { meta } }) => {
				if (meta) {
					setLeadsCount(meta.filter_count);
				} else {
					setLeadsCount(0)
				}
			})
			.catch((err) => {
				setLeadsCount(0)

				throw err;
				// setIsloading(false)
				//   setStatus('The login detail is incorrect')
			})
	}

	const getUpcomingLeadsList = (data, date) => {
		// if (e?.target?.value) {
		// 	setFilterDate(e?.target?.value)
		// }
		let query = `&filter[customer_id][eq]=${data.id}`
		let fields = `?fields=*.*.*`
		let filter = `${fields}${query}`

		getLists('LEADS', filter)
			.then(({ data: { data } }) => {
				if (data && data.length > 0) {
					setUpcomingleads(data);
				} else {
					setUpcomingleads([])
				}
			})
			.catch((err) => {
				setUpcomingleads([])
				throw err;
				// setIsloading(false)
				//   setStatus('The login detail is incorrect')
			})
	}

	const getSettings = () => {
		let filter = `/1?fields=*.*.*`;

		getLists('SETTINGS', filter)
			.then(({ data: { data } }) => {
				//console.log('customer reports', data);
				if (data) {
					setReport(data);

					//window.location.reload();
				} else {
					setReport('');
				}
			})
			.catch((err) => {
				throw err;
			});
	};

	function CustomTabPanel(props) {
		const { children, value, index, ...other } = props;

		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`simple-tabpanel-${index}`}
				aria-labelledby={`simple-tab-${index}`}
				{...other}
			>
				{value === index && (
					<Box sx={{ p: 3 }}>
						<Typography>{children}</Typography>
					</Box>
				)}
			</div>
		);
	}

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		};
	}
	function checkString(string) {
		return /^[0-9]*$/.test(string);
	}

	const send_SMS = async () => {
		// if (!message) {
		// 	//alert('Please enter at least one E-Mail');
		// 	// setEmtyMsg('NO_REP');
		// 	return;
		// }
		// console.log(values, "message", message)

		try {
			// for (let j = 0; j <= assetIDRows.length; j += 20) {
			// let filterassetIDRows = tempassetIDRows.slice(i, noofRequests + i);
			if (!manualMeeting && !googleEvent.description) {
				setMailStatus('FAIL');
				return
			}
			let leadId = ''
			if (!manualMeeting) {
				leadId = JSON.parse(googleEvent.description).lead
			} else {
				leadId = googleEvent.lead.id
			}
			if (!leadId) {
				setMailStatus('FAIL');
				return
			}
			let fil = ''
			fil = `&filter[id][eq]=${leadId}`

			let filter = `?filter[customer_id][eq]=${data.id}${fil}&fields=*.*.*&sort=-created_on&limit=-1`

			getLists('LEADS', filter)
				.then(({ data: { data } }) => {
					if (data && data.length > 0) {
						let phone = data[0].contactphone;
						// phone = '+46762466090';
						phone = phone.replace("+", '');
						// let checkNo = phone[0] + phone[1];
						// checkNo = '4608008008';
						// console.log("checkNo", checkNo)

						// if (checkNo) {
						// 	console.log(phone, "checkNo", phone.slice(0, 2))
						// }
						phone = phone.replace("46", '')
						// console.log("phone", phone)
						if (!checkString(phone)) {
							setMailStatus('FAIL');
							return
						}
						let msg = `Hej,Vi vill bara påminna dig om att godkänna kalenderinbjudan som äger rum den ${meetingStartDate}`
						let params = {
							phone_number: phone,
							message: msg
						}
						sendSms(params);
						setMailStatus('SUCCESS');
						setLoading(false);
						// setGoogleEvent('')
					}
				})
				.catch((err) => {
					setLeads([])
					setVisible(false)

					throw err;
					// setIsloading(false)
					//   setStatus('The login detail is incorrect')
				})

			// }
		} catch (err) {
			console.log('update error', err);
			// setLoading(false);
			// setError(false);
			setMailStatus('SUCCESS');
			setLoading(false);
			// setFileUploadErrorMessage(err.message);
			throw err;
		}
	};

	// useEffect(()=>{
	// 	if(meetingStartDate) {

	// 	}
	// },[meetingStartDate])

	return (
		<div style={{ width: '100%' }}>
			<div className='ms-5' style={{ display: 'flex' }}>
				<div style={{ width: '30%' }}>
					<h2 className="mb-10">{localLanguage.overview}</h2>
				</div>
				{/* <div>
					<a className="btn btn-success color-white ms-2 status-btn" onClick={() => setshowMeeting(true)} style={{ fontSize: '12px' }} disabled="true">
						{localLanguage.meeting}</a>
				</div> */}

			</div>
			{/* Chart Reports */}
			<div className="row g-5 g-xl-8">
				<div className="col-xl-12 col-xs-12">
					<div className="card-white">
						<div className="row g-5 g-xl-8">
							<div className="col-xl-8 card-white">
								<div className="card-white mb-5 border-1">
									<div className="flex-row mb-5" style={{ justifyContent: 'space-between' }}>
										<h4 className="ml-15">{localLanguage.statistic}</h4>
									</div>
									<div className="row g-5 g-xl-8">
										<div className="col-xl-6 col-xs-12">
											<Card
												type={"normal"}
												no_of_days={localLanguage.last_30_days}
												no_of_days_value={last30Days?.length}
												className="Card-small1"
											/>
										</div>
										<div className="col-xl-6 col-xs-12">
											<Chart
												data={areaData}
												height="300"
											>
												<ArgumentScale factory={scalePoint} />
												<ArgumentAxis />
												<ValueAxis />
												<AreaSeries
													name="Value"
													valueField="value"
													argumentField="year"
												/>
												{/* <Animation /> */}
												{/* <Visual */}
												<Legend
													position="bottom"
												// rootComponent={LegendRoot}
												// itemComponent={LegendItem}
												// labelComponent={LegendLabel}
												/>
												<Title
													text={localLanguage.ratio_confirmed_meetings}
												/>
											</Chart>
										</div>
									</div>
									<h2 className="border-bottom" />
									<div className="row g-5 g-xl-8">
										<div className="col-xl-4 col-xs-12">
											<Card
												type={"normal"}
												no_of_days={localLanguage.total_leads}
												no_of_days_value={leads?.length}
												className="Card-small2"
												bgcolor="#0095e8"
											/>

										</div>
										<div className="col-xl-4 col-xs-12">
											<Card
												type={"normal"}
												no_of_days={localLanguage.confirmed_meeting}
												no_of_days_value={confirmedmeeting?.length}
												className="Card-small2"
												bgcolor="#edb88c"
											/>
										</div>
										<div className="col-xl-4 col-xs-12">
											<Card
												type={"normal"}
												no_of_days={localLanguage.nuture}
												no_of_days_value={totalnuture}
												className="Card-small2"
												bgcolor="#fd7e14"
											/>
										</div>
									</div>
								</div>
								<div className="row g-5 g-xl-8">
									{/* <div className="col-xl-12 col-xs-12"> */}
									<div className="card-white">
										<Box sx={{ width: '100%' }}>
											<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
												<Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
													<Tab label={localLanguage.new_leads} {...a11yProps(0)} />
													<Tab label={localLanguage.ongoing} {...a11yProps(1)} />
													<Tab label={localLanguage.confirmed_meeting} {...a11yProps(2)} />
													{/* <Tab label={localLanguage.denied} {...a11yProps(3)} /> */}
												</Tabs>
											</Box>
											<CustomTabPanel value={value} index={0}>
												<div className="row">
													{
														<LeadsList
															data={currentUser}
															leads={newRecords}
															isDashboard={true}
															localLanguage={localLanguage}
															statusimages={statusimages}
															statuscolors={statuscolors}
															access_Token={access_Token}
															setAccessToken={setAccessToken}
															answersList={answersList}
															feedBackLists={feedBackLists}
															customerData={customerData}
															salesRepsList={salesReps}
															temptags={temptags}
															tags={tags}
															page="newleads"
														/>
													}
												</div>
											</CustomTabPanel>
											<CustomTabPanel value={value} index={1}>
												<div className="row">
													{
														<LeadsList
															data={currentUser}
															leads={leadsFilter}
															isDashboard={true}
															localLanguage={localLanguage}
															statusimages={statusimages}
															statuscolors={statuscolors}
															access_Token={access_Token}
															setAccessToken={setAccessToken}
															answersList={answersList}
															feedBackLists={feedBackLists}
															customerData={customerData}
															salesRepsList={salesReps}
															tags={tags}
															temptags={temptags}
														/>
													}
												</div>
											</CustomTabPanel>
											<CustomTabPanel value={value} index={2}>
												<div className="row">
													{
														<LeadsList
															data={currentUser}
															leads={confirmedmeeting}
															isDashboard={true}
															localLanguage={localLanguage}
															statusimages={statusimages}
															statuscolors={statuscolors}
															access_Token={access_Token}
															setAccessToken={setAccessToken}
															answersList={answersList}
															feedBackLists={feedBackLists}
															customerData={customerData}
															salesRepsList={salesReps}
														/>
													}
												</div>
											</CustomTabPanel>
											{/* <CustomTabPanel value={value} index={3}>
										<div className="row">
											{
												<LeadsList
													data={currentUser}
													leads={leadsFilter}
													localLanguage={localLanguage}
													statusimages={statusimages}
													statuscolors={statuscolors}
													access_Token={access_Token}
													setAccessToken={setAccessToken}
													answersList={answersList}
													feedBackLists={feedBackLists}
													customerData={customerData}
													salesReps={salesReps}
												/>
											}
										</div>
									</CustomTabPanel> */}
										</Box>
										{/* </div> */}
									</div>
								</div>
							</div>
							{
								<div className="col-xl-4">
									<div className="card-white">
										<div className="flex-row" style={{ justifyContent: 'space-between' }}>
											<h5 className="ml-5 mr-10">{localLanguage.upcoming_schedule} - {localLanguage.automate}</h5>
											{/* <DatePicker /> */}
										</div>
										<div className="flex-column upcoming_meets">
											{
												eventsList?.length > 0 ? eventsList.map((item, index) => (
													<BorderedCard
														eventDetail={item}
														title={item.summary ? item.summary : ''}
														borderColor={Colors[index]}
														localLanguage={localLanguage}
														icon={'bi bi-arrow-up fs-1x'}
														iconColor={'red'}
														event_status={item.event_status}
														SMSIMG={SMS}
														onclick={() => { setisManualMeeting(false); setMeetingStartDate(moment(item.start.dateTime).format('YYYY-MM-DD')); setGoogleEvent(item); setshowSmsModal(true); }}
														subTitle={item.start.dateTime ? moment(item.start.dateTime).format('YYYY-MM-DD') : ''}
														buttonName={''}
													/>
												)) : <div style={{ height: 80, textAlign: 'center', margin: 50, color: '#f1416cd1' }}>Inga kommande möten</div>
											}
										</div>
									</div>
									<div className="card-white">
										<div className="flex-row" style={{ justifyContent: 'space-between' }}>
											<h5 className="ml-5 mr-10">{localLanguage.upcoming_schedule} - {localLanguage.book_meeting}</h5>
											{/* <DatePicker /> */}
										</div>
										<div className="flex-column upcoming_meets">
											{
												eventsListManual?.length > 0 ? eventsListManual.map((item, index) => (
													<BorderedCard_manual
														eventDetail={item}
														title={item.summary ? item.summary : ''}
														borderColor={Colors[index]}
														localLanguage={localLanguage}
														icon={'bi bi-arrow-up fs-1x'}
														iconColor={'red'}
														event_status={item.event_status}
														SMSIMG={SMS}
														onclick={() => { setisManualMeeting(true); setMeetingStartDate(moment(item.meeting_start).format('YYYY-MM-DD')); setGoogleEvent(item); setshowSmsModal(true); }}
														subTitle={item.meeting_start ? moment(item.meeting_start).format('YYYY-MM-DD hh:mm:ss') : ''}
														buttonName={''}
													/>
												)) : <div style={{ height: 80, textAlign: 'center', margin: 50, color: '#f1416cd1' }}>Inga kommande möten</div>
											}
										</div>
									</div>
								</div>
							}
						</div>

					</div>
				</div>

			</div>

			{/* start::SMS modal */}
			<Modal
				className="modal fade"
				id="kt_modal_select_location"
				data-backdrop="static"
				tabIndex={-1}
				role="dialog"
				show={showSmsModal}
				dialogClassName="modal-lg"
				aria-hidden="true"
			>

				<div className="modal-content">
					<div className="modal-header">
						<h3 className="modal-title" style={{ width: '100%', marginLeft: 5 }}> {localLanguage.sms}</h3>
						<div className="btn btn-icon btn-sm btn-light-primary" onClick={() => { setGoogleEvent(); setshowSmsModal(false) }}>
							<KTSVG className="svg-icon-2" path="/media/icons/duotune/arrows/arr061.svg" />
						</div>
					</div>
					<div className="modal-body">
						<div>
							{/* <DatePicker
								// showTimeSelect={false}
								// minTime={new Date(0, 0, 0, 10, 0)}
								// maxTime={new Date(0, 0, 0, 22, 0)}
								// minDate={new Date()} //set the current date to disable past date
								onChange={date => setMeetingStartDate(date)}
								selected={meetingStartDate}
							// dateFormat="yyyy-MM-dd"
							/> */}
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DemoContainer components={['DatePicker']}>
									<DemoItem label={localLanguage.meeting_end_time}>
										<DatePicker label=""
											onChange={(newValue) => {
												setMeetingStartDate(moment(newValue.$d).format('YYYY-MM-DD'))
											}
											}
										/>
									</DemoItem>
								</DemoContainer>
							</LocalizationProvider>

							<br />
							<br />
							<div class="mb-10">
								<label for="exampleFormControlInput12" class="required form-label">
									{localLanguage.message}
								</label>
								{/* <textarea
									class="form-control form-control-solid"
									// disabled={true}
									rows={6}
									// value={`Hej,Vi vill bara påminna dig om att godkänna kalenderinbjudan som äger rum den ${googleEvent && moment(googleEvent.start.dateTime).format('YYYY-MM-DD')}`}
									onChange={(e) => {
										setMessage(e.target.value);
									}}
								> */}
								<div>
									{`Hej,
									Vi vill bara påminna dig om att godkänna kalenderinbjudan som äger rum den ${meetingStartDate}`}
								</div>
								{/* </textarea> */}
							</div>
							{mailstatus == "FAIL" ? (
								<div className='mt-5 mb-lg-15 alert alert-danger'>
									<div className='alert-text font-weight-bold'>{'Something went wrong'}</div>
								</div>
							) : null}
							{mailstatus == "SUCCESS" ? (
								<div className='mt-5 mb-lg-15 alert alert-success'>
									<div className='alert-text font-weight-bold'>{localLanguage.sms_sent}</div>
								</div>
							) : null}
						</div>
						<div className="modal-footer">

						</div>

						<a onClick={() => send_SMS()} className="btn btn-primary">
							{loading && (
								<CircularProgress className={"progressbar"} />
							)}{localLanguage.invite}</a>

					</div>
				</div>

			</Modal>
		</div>
	);
}

export { DashboardWrapper };
