import { API_URL } from './constant'
import axios from 'axios'
import { Directus } from '@directus/sdk';
import { updateLeads } from '../../../app/pages/dashboard/redux/DashboardCrud'
// const fetch = require('node-fetch');
export function cleanEmptyObj(obj) {
    for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "") {
            delete obj[propName];
        }
    }
    return obj
}
export function directUSAPIToken() {
    const directus = new Directus(API_URL);

    const token = directus.auth.token;

    return token
}

export function getCounts(tableName, param) {
    const headers = {
        'Authorization': `Bearer ${directUSAPIToken()}`
    }
    return axios.get(`${API_URL}/items/${tableName}${param}`, {
        params: {},
        headers
    })
}

export function updateApi(tableName, param) {
    const API = `${API_URL}/items/${tableName}/${param.id}`
    let headers = {
        'Authorization': `Bearer ${directUSAPIToken()}`
    }
  
    return axios.patch(`${API}`,
      param,
      headers
    )
  }

  export function addApi(tableName, param) {
    const API = `${API_URL}/items/${tableName}`
    let headers = {
        'Authorization': `Bearer ${directUSAPIToken()}`
    }
  
    return axios.post(`${API}`,
      param,
      headers
    )
  }