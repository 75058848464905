
export default function Card({
    image,
    title,
    designation,
    buttonName,
    iconColor,
    onclick,
    type,
    status_type,
    data,
    no_of_days,
    no_of_days_value,
    className,
    bgcolor
}) {
    return (
        type === 'normal' ? <div className={`Card-small ${className}`}>
            <div className="dot-color">
                {
                    bgcolor &&
                    <div className='card-small-icon-rounded dot-me' style={{ background: bgcolor }}>
                    </div>
                }
                <h4 className="lead_title1 "> {no_of_days} {!className ? ' :' : ''}</h4>

            </div>
            <h5 className="lead_desig1">{no_of_days_value}</h5>
            {/* <h5  className="lead_desig">{email}</h5> */}
        </div>
            :
            <div className={(data?.leadstatus == '' || data?.leadstatus == null) && !data?.isread ? 'Card-small bordernew' : 'Card-small'} onClick={onclick}>
                {(data?.leadstatus == '' || data?.leadstatus == null) && !data?.isread && <p className={'topbarInfo'}>Ny!</p>}
                <div className='card-icon-rounded' style={{ backgroundColor: iconColor }}>
                    <img src={image} alt="" className='invert-img' />
                </div>
                <h4 className="lead_title">{title}</h4>
                <h5 className="lead_desig">{designation}</h5>
                {/* <h5  className="lead_desig">{email}</h5> */}
                <h5 className="lead_desig">{status_type}</h5>
                <a onClick={onclick} className="btn btn-default" style={{ height: 26, lineHeight: '6px', fontSize: 10, backgroundColor: '#41c4f1' }}>{buttonName}</a>
            </div>
    )
}
