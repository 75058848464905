import SmsIcon from '@mui/icons-material/Sms';

export default function BorderedCard({
    title,
    borderColor,
    subTitle,
    onclick,
    event_status,
    localLanguage,
    SMSIMG,
    eventDetail
}) {
    // let status = event_status === 'accepted' ? 'confirmed' : 'unconfirmed'
    let color = 'btn-warning';
    let status = 'unconfirmed'
    if (eventDetail.event_status === 'confirmed') {
        color = 'btn-success';
        status = 'confirmed'
    } else if (eventDetail.event_status === 'declined') {
        color = 'btn-danger';
        status = 'declined'
    }

    return (
        <div className='Card-horizontal' style={{ borderLeftColor: borderColor }}>
            <div className="flex-row justify-between">
                <h6 style={{ fontSize: 10 }}>{eventDetail?.lead?.business_name}  {title ? ` - ${title}` : ''}</h6>
                <div>
                    <a className={`btn ${color} color-white ms-2 status-btn`} disabled="true">{localLanguage[status]}</a>
                    {status === 'unconfirmed' ? <img src={SMSIMG} onClick={onclick} className="smsicon" /> : null}
                </div>
            </div>
            <div className="flex-row justify-between mt-5">
                <h6 style={{ fontSize: 10, color: '#ccc' }}>{subTitle}</h6>
            </div>
        </div>
    )
}
